import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './iconfont/iconfont.css'
import VAnimation from 'v-animation'
// import animated from 'animate.css';
import bottom from '../src/components/bottom.vue'
// Vue.use(animated)
Vue.use(VAnimation)
Vue.component('HMbottom',bottom)
Vue.config.productionTip = false

new Vue({
  router,
  store:store,
  VAnimation,
  render: h => h(App)
}).$mount('#app')
