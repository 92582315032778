<template>
    <div v-if="iphone">
        <div>
            <video style="width: 100%;height: 100vh;object-fit: cover;" autoplay muted loop playsinline=""
                src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651906689606.mp4"></video>
            <div class="mask"></div>
            <div class="title wow animate__fadeInUp" style="animation: bottomTop 1s;">Anti-Strain
                暴走防护科技</div>
        </div>
        <div class="panel">
            <div style="padding-right: 50vw;font-size: 2.5vw;font-weight: 700;line-height: 3vw;">轻量鞋底科技</div>
            <div style="margin-top: .5vw; font-size: 1vw;font-weight: 200;line-height: 1.5vw;">FLEX CORE · 云感轻量鞋底 · 暴走不易疲倦
            </div>
        </div>
        <div class="content">
            <div style="display: flex;justify-content: space-between; padding: 0 15vw;">
                <div class="content-left">
                    <div class="name">
                        轻质回弹黑科技
                    </div>
                    <div style="font-size: .8vw;font-weight: 200;line-height: 1.5vw;">
                        鞋底经微发泡技术处理， <br>
                        无数气泡结构分布， <br>
                        能自动分散足部行走时的压力， <br>
                        自适应足部压强， <br>
                        记忆性功能塑造轻弹脚感， <br>
                        长时间行走轻盈不易疲倦。 <br>
                    </div>
                </div>
                <div class="content-right">
                    <div class="content-img"></div>
                </div>
            </div>
            <div
                style="display: flex; font-size:.8vw;text-align: center;flex-direction: row-reverse;padding: 0 15vw 5vw 0;height: 29vw;">
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="	https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651816142366.png" alt="">
                    </div>
                    <span style="margin-top: 2vw;">街旅系列云朵厚底低帮帆布鞋</span>
                </div>
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651816267020.png" alt="">
                    </div>
                    <span style="margin-top: 2vw;">丘郊系列帆布马丁靴</span>
                </div>
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651895397092.png" alt="">
                    </div>
                    <span style="margin-top: 2vw;">街旅系列厚底大头休闲鞋</span>
                </div>
            </div>
        </div>
        <div class="panel" style="text-align: right;">
            <div style="text-align: right; padding-left: 50vw;font-size: 2.5vw;font-weight: 700;line-height: 3vw;">缓震鞋垫科技
            </div>
            <div style="margin-top: .5vw; font-size: 1vw;font-weight: 200;line-height: 1.5vw;">ELAS FOAM · 云弹缓震鞋垫 · 行走省力
            </div>
        </div>
        <div class="content">
            <div style="display: flex;justify-content: space-between; padding: 0 15vw;height: 29vw;">
                <div class="content-right">
                    <div class="content-img"
                        style="background-image: url(	https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651816458470.png);">
                    </div>
                </div>
                <div class="content-left">
                    <div class="name">
                        缓震释压黑科技
                    </div>
                    <div style="font-size: .8vw;font-weight: 200;line-height: 1.5vw;">
                        鞋垫由多重密度级材质组合而成，<br>
                        形成复合型一体化结构， <br>
                        拥有强大的足底缓震支撑系统， <br>
                        脚部轮廓结构都能有所对应支撑及缓解，<br>
                        有针对性的为足底持续释放压力， <br>
                        长时间在户外行走的每一步都更轻松。 <br>
                    </div>
                </div>
            </div>
            <div style="display: flex; font-size:.8vw;text-align: center;padding: 0 15vw 5vw 15vw;">
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651816603348.png" alt="">
                    </div>
                    <span style="margin-top: 2vw;">街旅系列厚底高帮帆布鞋
                    </span>
                </div>
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1666769462299.jpg" alt="">
                    </div>
                    <span style="margin-top: 2vw;">丘郊系列盈弹厚底马丁靴</span>
                </div>
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651816793482.png" alt="">
                    </div>
                    <span style="margin-top: 2vw;">街旅系列魔术贴大头鞋</span>
                </div>
            </div>
        </div>
        <div class="dibu">
            <div>
                <div style="font-size: .7vw;line-height: .8vw;letter-spacing: 1px;font-weight: 700;margin-bottom: 2.08vw;">
                    联系我们
                </div>
                <div class="dibu-left" style="font-size: .7vw;line-height: .8vw;letter-spacing: 1px;margin-bottom: 2.08vw;">
                    <div style="color:#464748;">
                        <div style="margin-bottom: 1vw;">商务合作 <span style="margin: 0 10px;">cooperation@janezt.com</span>
                        </div>
                        <div>加入我们 <span style="margin: 0 10px;">hr@janezt.com</span> </div>
                    </div>
                    <div style="color:#464748;padding-left: 7vw;">
                        <div style="margin-bottom: 1vw;">联系电话 <span>0755-84532595</span></div>
                        <div>公司地址 <span>深圳市南山区粤海街道深大南大道9968号汉京金融中心14楼</span> </div>
                    </div>
                </div>
            </div>
            <div class="dibu-right">
                <div class="dibu-right-text">关注我们</div>
                <div class="lxfs">
                    <div class="iconfont icon-weibo"></div>
                    <div class="iconfont icon-weixin"></div>
                    <div class="iconfont icon-douyin"></div>
                    <div class="iconfont icon-xiaohongshu1"></div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div>
            <video style="width: 100%;height: 100vh;object-fit: cover;position: absolute;" autoplay muted loop playsinline
                src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651893889643.mp4"></video>
            <div style="background-color: rgba(0, 0, 0, .6);width: 100%;height: 100vh;z-index: 22;position: relative;">
                <div style="position: absolute;width: 100%;text-align: center;top: 50%;color: #ffffff;">
                    <div style="font-weight: 700;font-size: 8.5vw;">暴走防护科技</div>
                    <div style="font-weight: 300;font-size: 4.2vw;">Anti-Strain</div>
                </div>
            </div>
        </div>
        <div style="margin-top: 8.5vw;padding: 4.2vw;">
            <div style="font-size: 4.8vw;font-weight: 700;line-height: 5.3vw;">轻量鞋底科技</div>
            <div style="font-size: 3.5vw;margin-top: 2.1vw;">FLEX CORE · 云感轻量鞋底 · 暴走不易疲倦</div>
        </div>
        <div style="margin-top: 6.4vw;padding: 0 4.2vw;">
            <div style="font-size: 3.7vw;font-weight: 700;">轻质回弹黑科技</div>
            <div style="font-size: 3.2vw;font-weight: 200;margin-top: 2.1vw;">
                鞋底经微发泡技术处理，无数气泡结构分布，能自动分散足部行走时的压力，自适应足部压强，记忆性功能塑造轻弹脚感，长时间行走轻盈不易疲倦。
            </div>
            <div style="margin-top: 3.5vw;">
                <img style="width: 100%;height: 63.6vw;"
                    src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651739875541.png" alt="">
            </div>
            <div style="display: flex;width: 100%;">
                <div style="flex: 1;">
                    <img style="width: 100%;"
                        src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651816142366.png" alt="">
                    <div>挚暖系列摇粒绒打底衫</div>
                </div>
                <div style="flex: 1;">
                    <img style="width: 100%;"
                        src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651816267020.png" alt="">
                    <div>暖霁系列纷云薄款短款外套</div>
                </div>
                <div style="flex: 1;">
                    <img style="width: 100%;"
                        src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651895397092.png" alt="">
                    <div>炽煦系列短款羽绒服</div>
                </div>
            </div>
        </div>
        <div style="margin-top: 8.5vw;padding: 4.2vw;">
            <div style="font-size: 4.8vw;font-weight: 700;line-height: 5.3vw;">缓震鞋垫科技</div>
            <div style="font-size: 3.5vw;margin-top: 2.1vw;">ELAS FOAM · 云弹缓震鞋垫 · 行走省力</div>
        </div>
        <div style="margin-top: 6.4vw;padding: 0 4.2vw;">
            <div style="font-size: 3.7vw;font-weight: 700;">缓震释压黑科技</div>
            <div style="font-size: 3.2vw;font-weight: 200;margin-top: 2.1vw;">
                鞋垫由多重密度级材质组合而成，形成复合型一体化结构，拥有强大的足底缓震支撑系统，脚部轮廓结构都能有所对应支撑及缓解，有针对性的为足底持续释放压力，长时间在户外行走的每一步都更轻松。
            </div>
            <div style="margin-top: 3.5vw;">
                <img style="width: 100%;height: 63.6vw;"
                    src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651816458470.png" alt="">
            </div>
            <div style="display: flex;width: 100%;">
                <div style="flex: 1;">
                    <img style="width: 100%;"
                        src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651816603348.png" alt="">
                    <div>挚暖系列摇粒绒打底衫</div>
                </div>
                <div style="flex: 1;">
                    <img style="width: 100%;"
                        src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1666769462299.jpg" alt="">
                    <div>暖霁系列纷云薄款短款外套</div>
                </div>
                <div style="flex: 1;">
                    <img style="width: 100%;"
                        src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651816793482.png" alt="">
                    <div>炽煦系列短款羽绒服</div>
                </div>
            </div>
        </div>
        <div style="padding:15.6vw 5vw ;background-color: #f4f7fa;">
            <div style="display: flex;margin: 6vw  0;justify-content: space-between;border-radius: 1px solid #d3d6d8;">
                <div style="font-size: 4vw;color:#000;font-weight: 700;">社交媒体</div>
                <div>></div>
            </div>
            <div style="display: flex;margin: 6vw  0;justify-content: space-between;border-radius: 1px solid #d3d6d8;">
                <div style="font-size: 4vw;color:#000;font-weight: 700;">联络方式</div>
                <div>></div>
            </div>
            <div
                style="padding: 0 15vw;font-size: 3.5vw;line-height: 5.3vw;font-weight: 400;color: rgba(0,0, 0, .3);text-align: center;">
                © 2022 Beneunder 深圳减字科技有限公司.版权所有 <br>
                粤ICP备15047322号
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'noneIndex',
    data() {
        return {
            iphone: ''
        }
    },
    created() {
        this.getiphone()
    },
    methods: {
        getiphone() {
            const userAgent = navigator.userAgent.toLowerCase();
            const re =
                /(ipad|iphone os|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile)/i;
            this.iphone = !re.test(userAgent);
            console.log(this.iphone);
        },
    }
}
</script>
<style>
@keyframes bottomTop {
    from {
        opacity: 0;
        transform: translateY(00%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.panel {
    margin: 9vw 0 5vw 0;
    padding: 0 15vw;
}

.img-box {
    /* width: 13.5vw; */
    /* height: 13vw; */
    overflow: hidden;
    margin-bottom: 1.5vw;
}

.img-boxitem {
    width: 13.5vw;
    height: 13vw;
    margin-bottom: 1.5vw;
}

.img-boxitem:hover {
    transition: all .5s;
    transform: scale(1.07);
    /* width: 14vw;
    height: 14vw; */
}

.content-left {
    padding: 5vw 0;
}

.name {
    font-size: 1.25vw;
    color: black;
    font-weight: 700;
    line-height: 1.5vw;
    margin-bottom: .5vw;
}

.content-img {
    height: 31vw;
    width: 45vw;
    background-image: url(https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651739875541.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.content-img:hover {
    /* height: 33vw;
    width: 47vw; */
    transition: all .5s;
    transform: scale(1.07);
}

.content-right {
    position: relative;
    top: -55px;
    left: 0;
    height: 31vw;
    width: 45vw;
    overflow: hidden;
}

.content {
    background-color: #f4f7fa;
    /* padding: 0 15vw;
    display: flex;
    justify-content: space-between; */
}

.title {
    font-weight: 700;
    font-size: 5.2vw;
    text-align: center;
    line-height: 100vh;
}

.mask {
    background-color: rgba(0, 0, 0, .6);
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}
</style>