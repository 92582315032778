<template>
    <div v-if="iphone">
        <div>
            <video style="width: 100%;height: 100vh;object-fit: cover;" autoplay muted loop playsinline=""
                src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651906873417.mp4"></video>
            <div class="mask"></div>
            <div class="title wow animate__fadeInUp" style="animation: bottomTop 1s;">Anti-Water
                雨水防护科技</div>
        </div>
        <div class="panel">
            <div style="padding-right: 50vw;font-size: 2.5vw;font-weight: 700;line-height: 3vw;">三防易净科技</div>
            <div style="margin-top: .5vw; font-size: 1vw;font-weight: 200;line-height: 1.5vw;">AntiStain · 防水拒油 · 抗污易洗
            </div>
        </div>
        <div class="content">
            <div style="display: flex;justify-content: space-between; padding: 0 15vw;">
                <div class="content-left">
                    <div class="name">
                        高密表层净洗黑科技
                    </div>
                    <div style="font-size: .8vw;font-weight: 200;line-height: 1.5vw;">
                        采用超低表面科技纤维， <br>
                        表层密度低于水油等流体污渍， <br>
                        沾油不侵，遇水不渗*， <br>
                        同时融合反转净洗技术， <br>
                        提升水对于污渍的吸附能力， <br>
                        即使衣物脏污也能轻松清洗。<br>
                    </div>
                </div>
                <div class="content-right">
                    <div class="content-img"
                        style="background-image: url(https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1652352548375.jpg);">
                    </div>
                </div>
            </div>
            <div
                style="display: flex; font-size:.8vw;text-align: center;flex-direction: row-reverse;padding: 0 15vw 5vw 0;height: 23vw;">
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651740606393.png" alt="">
                    </div>
                    <span style="margin-top: 2vw;">澄久系列圆领T恤</span>
                </div>
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651740630469.png" alt="">
                    </div>
                    <span style="margin-top: 2vw;">澄久系列休闲卫衣</span>
                </div>
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651740653329.png" alt="">
                    </div>
                    <span style="margin-top: 2vw;">澄久系列休闲束脚裤</span>
                </div>
            </div>
        </div>
        <div class="panel" style="text-align: right;">
            <div style="text-align: right; padding-left: 50vw;font-size: 2.5vw;font-weight: 700;line-height: 3vw;">高效拒水科技
            </div>
            <div style="margin-top: .5vw; font-size: 1vw;font-weight: 200;line-height: 1.5vw;">WaterCombat · 滴水成珠 · 不易渗透
            </div>
        </div>
        <div class="content">
            <div style="display: flex;justify-content: space-between; padding: 0 15vw;height: 29vw;">
                <div class="content-right">
                    <div class="content-img"
                        style="background-image: url(https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651739827649.png);">
                    </div>
                </div>
                <div class="content-left">
                    <div class="name">
                        仿生抗水黑科技
                    </div>
                    <div style="font-size: .8vw;font-weight: 200;line-height: 1.5vw;">
                        表层附载一体仿生水膜，<br>
                        低表层密度能够抗雨水侵袭， <br>
                        具有仿荷叶拒水效果， <br>
                        滴水成珠，无畏大雨环境。
                    </div>
                </div>
            </div>
            <div style="display: flex; font-size:.8vw;text-align: center;padding: 0 15vw 5vw 15vw;">
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651740679947.png" alt="">
                    </div>
                    <span style="margin-top: 2vw;">起始系列直柄伞
                    </span>
                </div>
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="	https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651817762275.png" alt="">
                    </div>
                    <span style="margin-top: 2vw;">起始系列自动三折伞</span>
                </div>
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="	https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651817789705.png" alt="">
                    </div>
                    <span style="margin-top: 2vw;">蔓延系列三折伞</span>
                </div>
            </div>
        </div>
        <div class="dibu">
            <div>
                <div style="font-size: .7vw;line-height: .8vw;letter-spacing: 1px;font-weight: 700;margin-bottom: 2.08vw;">
                    联系我们
                </div>
                <div class="dibu-left" style="font-size: .7vw;line-height: .8vw;letter-spacing: 1px;margin-bottom: 2.08vw;">
                    <div style="color:#464748;">
                        <div style="margin-bottom: 1vw;">商务合作 <span style="margin: 0 10px;">cooperation@janezt.com</span>
                        </div>
                        <div>加入我们 <span style="margin: 0 10px;">hr@janezt.com</span> </div>
                    </div>
                    <div style="color:#464748;padding-left: 7vw;">
                        <div style="margin-bottom: 1vw;">联系电话 <span>0755-84532595</span></div>
                        <div>公司地址 <span>深圳市南山区粤海街道深大南大道9968号汉京金融中心14楼</span> </div>
                    </div>
                </div>
            </div>
            <div class="dibu-right">
                <div class="dibu-right-text">关注我们</div>
                <div class="lxfs">
                    <div class="iconfont icon-weibo"></div>
                    <div class="iconfont icon-weixin"></div>
                    <div class="iconfont icon-douyin"></div>
                    <div class="iconfont icon-xiaohongshu1"></div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div>
            <video style="width: 100%;height: 100vh;object-fit: cover;position: absolute;" autoplay muted loop playsinline
                src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651906873417.mp4"></video>
            <div style="background-color: rgba(0, 0, 0, .6);width: 100%;height: 100vh;z-index: 22;position: relative;">
                <div style="position: absolute;width: 100%;text-align: center;top: 50%;color: #ffffff;">
                    <div style="font-weight: 700;font-size: 8.5vw;">雨水防护科技</div>
                    <div style="font-weight: 300;font-size: 4.2vw;">Anti-Water /div>
                    </div>
                </div>
            </div>
            <div style="margin-top: 8.5vw;padding: 4.2vw;">
                <div style="font-size: 4.8vw;font-weight: 700;line-height: 5.3vw;">三防易净科技</div>
                <div style="font-size: 3.5vw;margin-top: 2.1vw;">AntiStain · 防水拒油 · 抗污易洗</div>
            </div>
            <div style="margin-top: 6.4vw;padding: 0 4.2vw;">
                <div style="font-size: 3.7vw;font-weight: 700;">高密表层净洗黑科技</div>
                <div style="font-size: 3.2vw;font-weight: 200;margin-top: 2.1vw;">
                    采用超低表面科技纤维，表层密度低于水油等流体污渍，沾油不侵，遇水不渗*，同时融合反转净洗技术，提升水对于污渍的吸附能力，即使衣物脏污也能轻松清洗。
                </div>
                <div style="margin-top: 3.5vw;">
                    <img style="width: 100%;height: 63.6vw;"
                        src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1652352548375.jpg" alt="">
                </div>
                <div style="display: flex;width: 100%;">
                    <div style="flex: 1;">
                        <img style="width: 100%;"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651740606393.png" alt="">
                        <div>澄久系列圆领T恤</div>
                    </div>
                    <div style="flex: 1;">
                        <img style="width: 100%;"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651740630469.png" alt="">
                        <div>澄久系列休闲卫衣</div>
                    </div>
                    <div style="flex: 1;">
                        <img style="width: 100%;"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651740653329.png" alt="">
                        <div>澄久系列休闲束脚裤-</div>
                    </div>
                </div>
            </div>
            <div style="margin-top: 8.5vw;padding: 4.2vw;">
                <div style="font-size: 4.8vw;font-weight: 700;line-height: 5.3vw;">高效拒水科技</div>
                <div style="font-size: 3.5vw;margin-top: 2.1vw;">WaterCombat · 滴水成珠 · 不易渗透</div>
            </div>
            <div style="margin-top: 6.4vw;padding: 0 4.2vw;">
                <div style="font-size: 3.7vw;font-weight: 700;">仿生抗水黑科技</div>
                <div style="font-size: 3.2vw;font-weight: 200;margin-top: 2.1vw;">
                    表层附载一体仿生水膜，低表层密度能够抗雨水侵袭，具有仿荷叶拒水效果，滴水成珠，无畏大雨环境。
                </div>
                <div style="margin-top: 3.5vw;">
                    <img style="width: 100%;height: 63.6vw;"
                        src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651739827649.png" alt="">
                </div>
                <div style="display: flex;width: 100%;">
                    <div style="flex: 1;">
                        <img style="width: 100%;"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651740679947.png" alt="">
                        <div>起始系列直柄伞</div>
                    </div>
                    <div style="flex: 1;">
                        <img style="width: 100%;"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651740679947.png" alt="">
                        <div>起始系列自动三折伞</div>
                    </div>
                    <div style="flex: 1;">
                        <img style="width: 100%;"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651817789705.png" alt="">
                        <div>蔓延系列三折伞</div>
                    </div>
                </div>
            </div>
            <div style="padding:15.6vw 5vw ;background-color: #f4f7fa;">
                <div style="display: flex;margin: 6vw  0;justify-content: space-between;border-radius: 1px solid #d3d6d8;">
                    <div style="font-size: 4vw;color:#000;font-weight: 700;">社交媒体</div>
                    <div>></div>
                </div>
                <div style="display: flex;margin: 6vw  0;justify-content: space-between;border-radius: 1px solid #d3d6d8;">
                    <div style="font-size: 4vw;color:#000;font-weight: 700;">联络方式</div>
                    <div>></div>
                </div>
                <div
                    style="padding: 0 15vw;font-size: 3.5vw;line-height: 5.3vw;font-weight: 400;color: rgba(0,0, 0, .3);text-align: center;">
                    © 2022 Beneunder 深圳减字科技有限公司.版权所有 <br>
                    粤ICP备15047322号
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'noneIndex',
    data() {
        return {
            iphone: ''
        }
    },
    created() {
        this.getiphone()
    },
    methods: {
        getiphone() {
            const userAgent = navigator.userAgent.toLowerCase();
            const re =
                /(ipad|iphone os|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile)/i;
            this.iphone = !re.test(userAgent);
            console.log(this.iphone);
        },
    }
}
</script>
<style>
@keyframes bottomTop {
    from {
        opacity: 0;
        transform: translateY(00%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.panel {
    margin: 9vw 0 5vw 0;
    padding: 0 15vw;
}

.img-box {
    /* width: 13.5vw; */
    /* height: 13vw; */
    overflow: hidden;
    margin-bottom: 1.5vw;
}

.img-boxitem {
    width: 13.5vw;
    height: 13vw;
    margin-bottom: 1.5vw;
}

.img-boxitem:hover {
    transition: all .5s;
    transform: scale(1.07);
    /* width: 14vw;
    height: 14vw; */
}

.content-left {
    padding: 5vw 0;
}

.name {
    font-size: 1.25vw;
    color: black;
    font-weight: 700;
    line-height: 1.5vw;
    margin-bottom: .5vw;
}

.content-img {
    height: 31vw;
    width: 45vw;
    background-image: url(https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651739875541.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.content-img:hover {
    /* height: 33vw;
    width: 47vw; */
    transition: all .5s;
    transform: scale(1.07);
}

.content-right {
    position: relative;
    top: -55px;
    left: 0;
    height: 31vw;
    width: 45vw;
    overflow: hidden;
}

.content {
    background-color: #f4f7fa;
    /* padding: 0 15vw;
    display: flex;
    justify-content: space-between; */
}

.title {
    font-weight: 700;
    font-size: 5.2vw;
    text-align: center;
    line-height: 100vh;
}

.mask {
    background-color: rgba(0, 0, 0, .6);
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}
</style>