<template>
    <div v-if="iphone">
        <div>
            <video style="width: 100%;height: 100vh;object-fit: cover;" autoplay muted loop playsinline=""
                src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651906758817.mp4"></video>
            <div class="mask"></div>
            <div class="title wow animate__fadeInUp" style="animation: bottomTop 1s;">Anti-UV
                日晒防护科技</div>
        </div>
        <div class="panel">
            <div style="padding-right: 50vw;font-size: 2.5vw;font-weight: 700;line-height: 3vw;">防晒黑胶Pro家族</div>
            <div style="margin-top: .5vw; font-size: 1vw;font-weight: 200;line-height: 1.5vw;">L.R.C · 隔热降温* · 高效防晒
            </div>
        </div>
        <div class="content">
            <div style="display: flex;justify-content: space-between; padding: 0 15vw;">
                <div class="content-left">
                    <div class="name">
                        多波段防晒黑科技
                    </div>
                    <div style="font-size: .8vw;font-weight: 200;line-height: 1.5vw;">
                        防晒微粒子经过不同比例钛元素精确配比， <br>
                        经7道控温加压处理，<br>
                        形成更稳定的黑胶因子，<br>
                        实现UVA、UVB、可见光VL及红外线IR多波段阻隔， <br>
                        帮你对抗阳光，防止肌肤光老化及晒伤晒黑。 <br>
                    </div>
                </div>
                <div class="content-right">
                    <div class="content-img"
                        style="background-image: url(https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651739343667.png);">
                    </div>
                </div>
            </div>
            <div
                style="display: flex; font-size:.8vw;text-align: center;flex-direction: row-reverse;padding: 0 15vw 5vw 0;height: 23vw;">
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1652352946213.jpg" alt="">
                    </div>
                    <span style="margin-top: 2vw;">口袋系列花苞五折伞</span>
                </div>
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="	https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1652352823631.jpg" alt="">
                    </div>
                    <span style="margin-top: 2vw;">天际系列远观防晒帽</span>
                </div>
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="	https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1652352716941.jpg" alt="">
                    </div>
                    <span style="margin-top: 2vw;">随身系列倍护六折扁伞</span>
                </div>
            </div>
        </div>
        <div class="panel">
            <div style="text-align: right; padding-left: 50vw;font-size: 2.5vw;font-weight: 700;line-height: 3vw;">防晒冰丝Pro
            </div>
            <div style="text-align: right;margin-top: .5vw; font-size: 1vw;font-weight: 200;line-height: 1.5vw;">AirLoop ·
                凉爽防晒 · 隔热降温*
            </div>
        </div>
        <div class="content">
            <div style="display: flex;justify-content: space-between; padding: 0 15vw;height: 29vw;">
                <div class="content-right">
                    <div class="content-img"
                        style="background-image: url(https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651739472130.png);">
                    </div>
                </div>
                <div class="content-left">
                    <div class="name">
                        凉爽黑科技纤维
                    </div>
                    <div style="font-size: .8vw;font-weight: 200;line-height: 1.5vw;">
                        由高热传导型纤维纺制，<br>
                        形成一体化强力导热层持续释放身体热气*， <br>
                        在纺制过程中注入微米级防晒粒子， <br>
                        确保凉爽同时拥有稳定的高效防晒性能， <br>
                        面料表层密布百万细密透气孔隙， <br>
                        使体感温度瞬即下降确保穿着凉感透气。 <br>
                    </div>
                </div>
            </div>
            <div style="display: flex; font-size:.8vw;text-align: center;padding: 0 15vw 5vw 15vw;">
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651738106675.png" alt="">
                    </div>
                    <span style="margin-top: 2vw;">冰触系列兰德披肩防晒服
                    </span>
                </div>
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="	https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651738131683.png" alt="">
                    </div>
                    <span style="margin-top: 2vw;">冰薄系列护眼角防晒口罩</span>
                </div>
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651738303131.png" alt="">
                    </div>
                    <span style="margin-top: 2vw;">凝丝系列罗曼防晒手套</span>
                </div>
            </div>
        </div>
        <div class="panel">
            <div style="padding-right: 50vw;font-size: 2.5vw;font-weight: 700;line-height: 3vw;">超薄皮防晒</div>
            <div style="margin-top: .5vw; font-size: 1vw;font-weight: 200;line-height: 1.5vw;">CoolKnit · 仿生物材质 · 轻薄防晒
            </div>
        </div>
        <div class="content">
            <div style="display: flex;justify-content: space-between; padding: 0 15vw;">
                <div class="content-left">
                    <div class="name">
                        微米级纤透黑科技纤维
                    </div>
                    <div style="font-size: .8vw;font-weight: 200;line-height: 1.5vw;">
                        采用高紫外线阻隔率的微米级超细纤维， <br>
                        细密技术编织而成，<br>
                        近似皮肤接触感，<br>
                        仿生透气轻若无物，<br>
                        轻薄防晒不压身。 <br>
                    </div>
                </div>
                <div class="content-right">
                    <div class="content-img"
                        style="background-image: url(https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651739532711.png);">
                    </div>
                </div>
            </div>
            <div
                style="display: flex; font-size:.8vw;text-align: center;flex-direction: row-reverse;padding: 0 15vw 5vw 0;height: 19vw;">
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651738382753.png" alt="">
                    </div>
                    <span style="margin-top: 2vw;">云薄系列瑞斯披肩防晒服</span>
                </div>
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651738409369.png" alt="">
                    </div>
                    <span style="margin-top: 2vw;">云薄系列皑川男士防晒服</span>
                </div>
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-boxitem"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651738437844.png" alt="">
                    </div>
                    <span style="margin-top: 2vw;">云翌系列蒲轻防晒服</span>
                </div>
            </div>
        </div>
        <div class="dibu">
            <div>
                <div style="font-size: .7vw;line-height: .8vw;letter-spacing: 1px;font-weight: 700;margin-bottom: 2.08vw;">
                    联系我们
                </div>
                <div class="dibu-left" style="font-size: .7vw;line-height: .8vw;letter-spacing: 1px;margin-bottom: 2.08vw;">
                    <div style="color:#464748;">
                        <div style="margin-bottom: 1vw;">商务合作 <span style="margin: 0 10px;">cooperation@janezt.com</span>
                        </div>
                        <div>加入我们 <span style="margin: 0 10px;">hr@janezt.com</span> </div>
                    </div>
                    <div style="color:#464748;padding-left: 7vw;">
                        <div style="margin-bottom: 1vw;">联系电话 <span>0755-84532595</span></div>
                        <div>公司地址 <span>深圳市南山区粤海街道深大南大道9968号汉京金融中心14楼</span> </div>
                    </div>
                </div>
            </div>
            <div class="dibu-right">
                <div class="dibu-right-text">关注我们</div>
                <div class="lxfs">
                    <div class="iconfont icon-weibo"></div>
                    <div class="iconfont icon-weixin"></div>
                    <div class="iconfont icon-douyin"></div>
                    <div class="iconfont icon-xiaohongshu1"></div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div>
            <video style="width: 100%;height: 100vh;object-fit: cover;position: absolute;" autoplay muted loop playsinline
                src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651910156223.mp4"></video>
            <div style="background-color: rgba(0, 0, 0, .6);width: 100%;height: 100vh;z-index: 22;position: relative;">
                <div style="position: absolute;width: 100%;text-align: center;top: 50%;color: #ffffff;">
                    <div style="font-weight: 700;font-size: 8.5vw;">日晒防护科技</div>
                    <div style="font-weight: 300;font-size: 4.2vw;">Anti-UV </div>
                </div>
            </div>
        </div>
        <div style="margin-top: 8.5vw;padding: 4.2vw;">
            <div style="font-size: 4.8vw;font-weight: 700;line-height: 5.3vw;">防晒黑胶Pro家族</div>
            <div style="font-size: 3.5vw;margin-top: 2.1vw;">L.R.C · 隔热降温* · 高效防晒</div>
        </div>
        <div style="margin-top: 6.4vw;padding: 0 4.2vw;">
            <div style="font-size: 3.7vw;font-weight: 700;">多波段防晒黑科技</div>
            <div style="font-size: 3.2vw;font-weight: 200;margin-top: 2.1vw;">
                防晒微粒子经过不同比例钛元素精确配比，经7道控温加压处理，形成更稳定的黑胶因子，实现UVA、UVB、可见光VL及红外线IR多波段阻隔，帮你对抗阳光，防止肌肤光老化及晒伤晒黑。
            </div>
            <div style="margin-top: 3.5vw;">
                <img style="width: 100%;height: 63.6vw;"
                    src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651739343667.png" alt="">
            </div>
            <div style="display: flex;width: 100%;">
                <div style="flex: 1;">
                    <img style="width: 100%;"
                        src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1652352946213.jpg" alt="">
                    <div>口袋系列花苞五折伞</div>
                </div>
                <div style="flex: 1;">
                    <img style="width: 100%;"
                        src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1652352823631.jpg" alt="">
                    <div>天际系列远观防晒帽</div>
                </div>
                <div style="flex: 1;">
                    <img style="width: 100%;"
                        src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1652352716941.jpg" alt="">
                    <div>随身系列倍护六折扁伞</div>
                </div>
            </div>
        </div>
        <div style="margin-top: 8.5vw;padding: 4.2vw;">
            <div style="font-size: 4.8vw;font-weight: 700;line-height: 5.3vw;">防晒冰丝Pro</div>
            <div style="font-size: 3.5vw;margin-top: 2.1vw;">AirLoop · 凉爽防晒 · 隔热降温*</div>
        </div>
        <div style="margin-top: 6.4vw;padding: 0 4.2vw;">
            <div style="font-size: 3.7vw;font-weight: 700;">凉爽黑科技纤维</div>
            <div style="font-size: 3.2vw;font-weight: 200;margin-top: 2.1vw;">
                由高热传导型纤维纺制，形成一体化强力导热层持续释放身体热气*，在纺制过程中注入微米级防晒粒子，确保凉爽同时拥有稳定的高效防晒性能，面料表层密布百万细密透气孔隙，使体感温度瞬即下降确保穿着凉感透气。
            </div>
            <div style="margin-top: 3.5vw;">
                <img style="width: 100%;height: 63.6vw;"
                    src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651739472130.png" alt="">
            </div>
            <div style="display: flex;width: 100%;">
                <div style="flex: 1;">
                    <img style="width: 100%;"
                        src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651738106675.png" alt="">
                    <div>挚暖系列摇粒绒打底衫</div>
                </div>
                <div style="flex: 1;">
                    <img style="width: 100%;"
                        src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651738131683.png" alt="">
                    <div>暖霁系列纷云薄款短款外套</div>
                </div>
                <div style="flex: 1;">
                    <img style="width: 100%;"
                        src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651738303131.png" alt="">
                    <div>炽煦系列短款羽绒服</div>
                </div>
            </div>
        </div>
        <div style="margin-top: 8.5vw;padding: 4.2vw;">
            <div style="font-size: 4.8vw;font-weight: 700;line-height: 5.3vw;">超薄皮防晒</div>
            <div style="font-size: 3.5vw;margin-top: 2.1vw;">CoolKnit · 仿生物材质 · 轻薄防晒</div>
        </div>
        <div style="margin-top: 6.4vw;padding: 0 4.2vw;">
            <div style="font-size: 3.7vw;font-weight: 700;">微米级纤透黑科技纤维</div>
            <div style="font-size: 3.2vw;font-weight: 200;margin-top: 2.1vw;">
                采用高紫外线阻隔率的微米级超细纤维，
                细密技术编织而成，
                近似皮肤接触感，
                仿生透气轻若无物，
                轻薄防晒不压身。
            </div>
            <div style="margin-top: 3.5vw;">
                <img style="width: 100%;height: 63.6vw;"
                    src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651739532711.png" alt="">
            </div>
            <div style="display: flex;width: 100%;">
                <div style="flex: 1;">
                    <img style="width: 100%;"
                        src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651738382753.png" alt="">
                    <div>云薄系列瑞斯披肩防晒服</div>
                </div>
                <div style="flex: 1;">
                    <img style="width: 100%;"
                        src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651738409369.png" alt="">
                    <div>云薄系列皑川男士防晒服</div>
                </div>
                <div style="flex: 1;">
                    <img style="width: 100%;"
                        src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651738437844.png" alt="">
                    <div>云翌系列蒲轻防晒服</div>
                </div>
            </div>
        </div>
        <div style="padding:15.6vw 5vw ;background-color: #f4f7fa;">
            <div style="display: flex;margin: 6vw  0;justify-content: space-between;border-radius: 1px solid #d3d6d8;">
                <div style="font-size: 4vw;color:#000;font-weight: 700;">社交媒体</div>
                <div>></div>
            </div>
            <div style="display: flex;margin: 6vw  0;justify-content: space-between;border-radius: 1px solid #d3d6d8;">
                <div style="font-size: 4vw;color:#000;font-weight: 700;">联络方式</div>
                <div>></div>
            </div>
            <div
                style="padding: 0 15vw;font-size: 3.5vw;line-height: 5.3vw;font-weight: 400;color: rgba(0,0, 0, .3);text-align: center;">
                © 2022 Beneunder 深圳减字科技有限公司.版权所有 <br>
                粤ICP备15047322号
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'noneIndex',
    data() {
        return {
            iphone: ''
        }
    },
    created() {
        this.getiphone()
    },
    methods: {
        getiphone() {
            const userAgent = navigator.userAgent.toLowerCase();
            const re =
                /(ipad|iphone os|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile)/i;
            this.iphone = !re.test(userAgent);
            console.log(this.iphone);
        },
    }
}
</script>
<style>
@keyframes bottomTop {
    from {
        opacity: 0;
        transform: translateY(00%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.panel {
    margin: 9vw 0 5vw 0;
    padding: 0 15vw;
}

.img-box {
    /* width: 13.5vw; */
    /* height: 13vw; */
    overflow: hidden;
    margin-bottom: 1.5vw;
}

.img-boxitem {
    width: 13.5vw;
    height: 13vw;
    margin-bottom: 1.5vw;
}

.img-boxitem:hover {
    transition: all .5s;
    transform: scale(1.07);
    /* width: 14vw;
    height: 14vw; */
}

.content-left {
    padding: 5vw 0;
}

.name {
    font-size: 1.25vw;
    color: black;
    font-weight: 700;
    line-height: 1.5vw;
    margin-bottom: .5vw;
}

.content-img {
    height: 31vw;
    width: 45vw;
    background-image: url(https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651739875541.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.content-img:hover {
    /* height: 33vw;
    width: 47vw; */
    transition: all .5s;
    transform: scale(1.07);
}

.content-right {
    position: relative;
    top: -55px;
    left: 0;
    height: 31vw;
    width: 45vw;
    overflow: hidden;
}

.content {
    background-color: #f4f7fa;
    /* padding: 0 15vw;
    display: flex;
    justify-content: space-between; */
}

.title {
    font-weight: 700;
    font-size: 5.2vw;
    text-align: center;
    line-height: 100vh;
}

.mask {
    background-color: rgba(0, 0, 0, .6);
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}
</style>