var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.iphone)?_c('div',{staticClass:"tobu"},[_c('video',{staticStyle:{"height":"100vh","width":"100%","object-fit":"cover"},attrs:{"autoplay":"","muted":"","loop":"","playsinline":"","src":"https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651907739599.mp4"},domProps:{"muted":true}}),_c('div',{staticClass:"mask"}),_c('div',{staticClass:"title wow animate__fadeInUp",staticStyle:{"animation":"bottomTop 1s"}},[_vm._v("Anti-Cold 寒冷防护科技")]),_vm._m(0),_vm._m(1),_vm._m(2)]):_c('div',[_c('div',[_c('video',{staticStyle:{"width":"100%","height":"100vh","object-fit":"cover","position":"absolute"},attrs:{"autoplay":"","muted":"","loop":"","playsinline":"","src":"https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651909751892.mp4"},domProps:{"muted":true}}),_vm._m(3)]),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel"},[_c('div',{staticStyle:{"padding-right":"50vw","font-size":"2.5vw","font-weight":"700","line-height":"3vw"}},[_vm._v("寒冷防护科技")]),_c('div',{staticStyle:{"margin-top":".5vw","font-size":"1vw","font-weight":"200","line-height":"1.5vw"}},[_vm._v("HeatLock · 锁温蓄热 · 轻盈保暖")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","padding":"0 15vw"}},[_c('div',{staticClass:"content-left"},[_c('div',{staticClass:"name"},[_vm._v(" 蓄暖升温黑科技 ")]),_c('div',{staticStyle:{"font-size":".8vw","font-weight":"200","line-height":"1.5vw"}},[_vm._v(" 寒冷防护科技由四重蓄暖原理组成， "),_c('br'),_vm._v(" 根据面料特性分为科技纤维导热蓄暖、 "),_c('br'),_vm._v(" 皮肤呼吸湿气热能转换、远红外蓄暖、 "),_c('br'),_vm._v(" 高密度绒毛空气锁温四种不同保暖方式， "),_c('br'),_vm._v(" 四种方式均能减少热量散失，持久蓄热， "),_c('br'),_vm._v(" 使得身体处于寒冷环境下仍能保持温暖感受。 "),_c('br')])]),_c('div',{staticClass:"content-right"},[_c('div',{staticClass:"content-img"})])]),_c('div',{staticStyle:{"display":"flex","font-size":".8vw","text-align":"center","flex-direction":"row-reverse","padding":"0 15vw 5vw 0"}},[_c('div',{staticStyle:{"margin-right":"2vw"}},[_c('div',{staticClass:"img-box"},[_c('img',{staticClass:"img-box",attrs:{"src":"\thttps://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1666769552861.jpg","alt":""}})]),_c('span',{staticStyle:{"margin-top":"2vw"}},[_vm._v("挚暖系列摇粒绒打底衫")])]),_c('div',{staticStyle:{"margin-right":"2vw"}},[_c('div',{staticClass:"img-box"},[_c('img',{staticClass:"img-box",attrs:{"src":"\thttps://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1666769536442.jpg","alt":""}})]),_c('span',{staticStyle:{"margin-top":"2vw"}},[_vm._v("暖霁系列纷云薄款短款外套")])]),_c('div',{staticStyle:{"margin-right":"2vw"}},[_c('div',{staticClass:"img-box"},[_c('img',{staticClass:"img-box",attrs:{"src":"https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1666769548607.jpg","alt":""}})]),_c('span',{staticStyle:{"margin-top":"2vw"}},[_vm._v("炽煦系列短款羽绒服")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dibu"},[_c('div',[_c('div',{staticStyle:{"font-size":".7vw","line-height":".8vw","letter-spacing":"1px","font-weight":"700","margin-bottom":"2.08vw"}},[_vm._v(" 联系我们 ")]),_c('div',{staticClass:"dibu-left",staticStyle:{"font-size":".7vw","line-height":".8vw","letter-spacing":"1px","margin-bottom":"2.08vw"}},[_c('div',{staticStyle:{"color":"#464748"}},[_c('div',{staticStyle:{"margin-bottom":"1vw"}},[_vm._v("商务合作 "),_c('span',{staticStyle:{"margin":"0 10px"}},[_vm._v("cooperation@janezt.com")])]),_c('div',[_vm._v("加入我们 "),_c('span',{staticStyle:{"margin":"0 10px"}},[_vm._v("hr@janezt.com")])])]),_c('div',{staticStyle:{"color":"#464748","padding-left":"7vw"}},[_c('div',{staticStyle:{"margin-bottom":"1vw"}},[_vm._v("联系电话 "),_c('span',[_vm._v("0755-84532595")])]),_c('div',[_vm._v("公司地址 "),_c('span',[_vm._v("深圳市南山区粤海街道深大南大道9968号汉京金融中心14楼")])])])])]),_c('div',{staticClass:"dibu-right"},[_c('div',{staticClass:"dibu-right-text"},[_vm._v("关注我们")]),_c('div',{staticClass:"lxfs"},[_c('div',{staticClass:"iconfont icon-weibo"}),_c('div',{staticClass:"iconfont icon-weixin"}),_c('div',{staticClass:"iconfont icon-douyin"}),_c('div',{staticClass:"iconfont icon-xiaohongshu1"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"rgba(0, 0, 0, .6)","width":"100%","height":"100vh","z-index":"22","position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","width":"100%","text-align":"center","top":"50%","color":"#ffffff"}},[_c('div',{staticStyle:{"font-weight":"700","font-size":"8.5vw"}},[_vm._v("寒冷防护科技")]),_c('div',{staticStyle:{"font-weight":"300","font-size":"4.2vw"}},[_vm._v("Anti-Cold ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"8.5vw","padding":"4.2vw"}},[_c('div',{staticStyle:{"font-size":"4.8vw","font-weight":"700","line-height":"5.3vw"}},[_vm._v("寒冷防护科技")]),_c('div',{staticStyle:{"font-size":"3.5vw","margin-top":"2.1vw"}},[_vm._v("HeatLock · 锁温蓄热 · 轻盈保暖")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"6.4vw","padding":"0 4.2vw"}},[_c('div',{staticStyle:{"font-size":"3.7vw","font-weight":"700"}},[_vm._v("蓄暖升温黑科技")]),_c('div',{staticStyle:{"font-size":"3.2vw","font-weight":"200","margin-top":"2.1vw"}},[_vm._v(" 寒冷防护科技由四重蓄暖原理组成，根据面料特性分为科技纤维导热蓄暖、皮肤呼吸湿气热能转换、远红外蓄暖、高密度绒毛空气锁温四种不同保暖方式，四种方式均能减少热量散失，持久蓄热，使得身体处于寒冷环境下仍能保持温暖感受。 ")]),_c('div',{staticStyle:{"margin-top":"3.5vw"}},[_c('img',{staticStyle:{"width":"100%","height":"63.6vw"},attrs:{"src":"https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1652247737335.jpg","alt":""}})]),_c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1666769552861.jpg","alt":""}}),_c('div',[_vm._v("挚暖系列摇粒绒打底衫")])]),_c('div',{staticStyle:{"flex":"1"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1666769536442.jpg","alt":""}}),_c('div',[_vm._v("暖霁系列纷云薄款短款外套")])]),_c('div',{staticStyle:{"flex":"1"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1666769548607.jpg","alt":""}}),_c('div',[_vm._v("炽煦系列短款羽绒服")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"15.6vw 5vw","background-color":"#f4f7fa"}},[_c('div',{staticStyle:{"display":"flex","margin":"6vw  0","justify-content":"space-between","border-radius":"1px solid #d3d6d8"}},[_c('div',{staticStyle:{"font-size":"4vw","color":"#000","font-weight":"700"}},[_vm._v("社交媒体")]),_c('div',[_vm._v(">")])]),_c('div',{staticStyle:{"display":"flex","margin":"6vw  0","justify-content":"space-between","border-radius":"1px solid #d3d6d8"}},[_c('div',{staticStyle:{"font-size":"4vw","color":"#000","font-weight":"700"}},[_vm._v("联络方式")]),_c('div',[_vm._v(">")])]),_c('div',{staticStyle:{"padding":"0 15vw","font-size":"3.5vw","line-height":"5.3vw","font-weight":"400","color":"rgba(0,0, 0, .3)","text-align":"center"}},[_vm._v(" © 2022 Beneunder 深圳减字科技有限公司.版权所有 "),_c('br'),_vm._v(" 粤ICP备15047322号 ")])])
}]

export { render, staticRenderFns }