import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import brands from '../views/brand.vue'
import contact from '../views/contact.vue'
import one from '../views/aboutlevel/one.vue'
import two from '../views/aboutlevel/two.vue'
import three from '../views/aboutlevel/three.vue'
import four from '../views/aboutlevel/four.vue'
import five from '../views/aboutlevel/five.vue'
import about from '../views/AboutView.vue'
import title from '../views/titleindex.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/title',
    name: 'title',
    component: title
  },
  {
    path: '/about',
    name: 'about',
    component:about,
  },
  {path:'/four', name:'four',component:four},
  {path:'/five', name:'five',component:five},
  {path:'/three', name:'three',component:three},
  {path:'/two',name:'two', component:two},
  {
    path:'/one',  
    component:one
  },
  {
    path:'/brand',
    name:'brand',
    component:brands
  },
  {
    path:'/contact',
    name:contact,
    component:contact
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
