<template>
  <div class="home">
    <div v-if="iphone" class="herder">
      <div :style="curMenus.imgUrl == undefined ? 'height:1vw;' : ''" class="wow animate__fadeInUp animate__animated "
        style="color: rgb(230, 224, 224);text-align: center;height: 500px;line-height: 500px;font-weight: 700;font-size:  5.2083333333vw;">
      </div>
      <div class="brand-story">
        <div class="logo-text" :style="count == 26 ? 'display: none;' : ''" v-for="(item) in queryList" :key="item.id">
          <div :id="count == 26 ? item.id : item.menuId" style="position: relative; top: -2vw;"></div>
          <div class="text">
            <ul style="font-size: 2vw;font-weight: 700;margin-bottom: 2.6vw;margin-top: 2.6vw;">{{
              item.menuTitle }}</ul>
            <div :style="item.imgUrl == null ? 'display: none;' : ''" v-if="item.title == null" v-html="item.menuContent"
              class="wow animate__fadeInUp animate__animated ">
            </div>
          </div>
          <div :style="item.imgUrl == null ? 'display: none;' : ''" class="wow animate__animated animate__fadeInRight"
            style="width: 29vw;height: 44vw;"><img style="width: 29vw;height: 44vw;" :src="item.imgUrl" alt="">
          </div>
        </div>
        <div :style="count == 26 ? '' : 'display: none;'">
          <div style="margin-bottom: 12rem;margin-top: 3rem;color: #808080;">
            <div style="text-align: center;margin-top: 8vw; font-size: 1.5vw;margin: 2rem 0;">行业新闻</div>
            <div style="display: flex;">
              <div :key="item.id" v-for="(item) in evenlists" :style="item.menuId == 28 ? '' : 'display:none;'"
                @click="clicktitle(item)" style=";margin-right: 2rem;width: 30%;">
                <div :id="item.menuId" style="position: relative; top: -5vw;"></div>
                <div style="font-size: 1vw; padding: 2.5vw;flex: 1;background-color:#f2f2f2;height: 3vw;">
                  <div>{{ item.title }}</div>
                  <div style="margin-top: 1vw;text-align: right;font-size: 0.8vw;">{{ item.createTime }}</div>
                </div>
                <div style="width: 100%;">
                  <img style="width: 100%;height: 100%;" :src="item.imgUrl" alt="">
                </div>
              </div>
            </div>
          </div>
          <div style="margin-bottom: 12rem;margin-top: 3rem;color: #808080;">
            <div style="text-align: center;margin-top: 8vw; font-size: 1.5vw;margin: 2rem 0;">公司新闻</div>
            <div style="display: flex;">
              <div :key="item.id" v-for="(item) in queryList" :style="item.menuId !== 28 ? '' : 'display:none;'"
                @click="clicktitle(item)" style=";margin-right: 2rem;width: 30%;">
                <div :id="item.menuId" style="position: relative; top: -5vw;"></div>
                <div style="font-size: 1vw; padding: 2.5vw;flex: 1;background-color:#f2f2f2;height: 3vw;">
                  <div>{{ item.title }}</div>
                  <div style="margin-top: 1vw;text-align: right;font-size: 0.8vw;">{{ item.createTime }}</div>
                </div>
                <div style="width: 100%;">
                  <img style="width: 100%;height: 100%;" :src="item.imgUrl" alt="">
                </div>
              </div>
            </div>
          </div>
          <!-- <div :style="count == 26 ? '' : 'display: none;'" style="margin-bottom: 12rem;margin-top: 3rem;color: #808080;">
            <div style="text-align: center;margin-top: 8vw; font-size: 1.5vw;margin: 2rem 0;">公司新闻</div>
            <div style="display: flex;">
              <div v-for="(item) in queryList" :style="item.menuId !== 28 ? '' : 'display:none;'" :key="item.id"
                @click="clicktitle(item)" style=";margin-right: 2rem;width: 30%;">
                <div :id="item.menuId" style="position: relative; top: -2vw;"></div>
                <div style="font-size: 1vw; padding: 2.5vw;flex: 1;background-color:#f2f2f2;height: 3vw;">
                  <div>{{ item.title }}</div>
                  <div style="margin-top: 1vw;text-align: right;font-size: 0.8vw;">{{ item.createTime }}</div>
                </div>
                <div style="width: 100%;">
                  <img style="width: 100%;height: 100%;" :src="item.imgUrl" alt="">
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <HMbottom></HMbottom>
    </div>
    <div v-else>
      <div style="height: 200px;">
        <div style="position: relative;">
          <div>
            <img style="width: 100%;" :src="bgimg" alt="">
          </div>
          <div style="text-align: center; color: #ececec;width: 100%;position: absolute;z-index: 99;top: 50%;">
            <!-- <div style="color: #ffffff;">
              <div style="font-size: 8.5vw;font-weight: 700;">About Us</div>
              <div style="font-size: 3.7vw;margin-top: 2.6vw;font-weight: 300;"> 关 / 于 / 蕉 / 下</div>
            </div> -->
          </div>
        </div>
        <div :style="count == 26 ? 'display:none' : ''" v-for="(item) in queryList" :key="item.id"
          style="margin-top: 12.8vw;padding: 0 4.2vw;">
          <div style="font-size: 5.3vw;font-weight: 700;margin-bottom: 4.26vw;">{{
            item.menuTitle }}</div>
          <div v-html="item.menuContent"
            style="font-weight: 300;font-size: 3.2vw;line-height: 4.3vw;margin-bottom: 4.26vw;letter-spacing: .13333333vw;">
          </div>
          <div>
            <img style="width: 100%;" :src="item.imgUrl" alt="">
          </div>
        </div>
        <div :style="count == 26 ? 'display:none' : ''" v-for="(item) in queryList" :key="item.id"
          style="margin-top: 12.8vw;padding: 0 4.2vw;">
          <div style="font-size: 5.3vw;font-weight: 700;margin-bottom: 4.26vw;">{{
            item.menuTitle }}</div>
          <div v-html="item.menuContent"
            style="font-weight: 300;font-size: 3.2vw;line-height: 4.3vw;margin-bottom: 4.26vw;letter-spacing: .13333333vw;">
          </div>
          <div>
            <img style="width: 100%;" :src="item.imgUrl" alt="">
          </div>
        </div>
        <div :style="count == 26 ? '' : 'display:none;'">
          <div style="margin: 10vw 0;text-align: center;font-size: 7vw;">新闻动态</div>
          <div v-for="(item, index) in queryList" :key="index" :style="total == index ? '' : 'display:none;'"
            style="padding: 0 3.5vw;">
            <div @click="clicktitle(item)"
              style="background-color: #f2f2f2;padding: 8.5vw;margin-bottom: 2vw;color: #8a8a8a;">
              <div style="margin-bottom: 1vw;font-weight: bold;">{{ item.createTime }}</div>
              <div>{{ item.title }}</div>
            </div>
            <div style="width: 100%;">
              <img style="width: 100%;height: 100%;" :src="item.imgUrl" alt="">
            </div>
          </div>
          <div style="display: flex;padding: 5vw;">
            <div class="iconfont icon-shangyibu" style="font-size: 10vw;flex: 1;" @click="totaland(-1)"></div>
            <div style="flex: 1;text-align: center;font-size: 10vw;">{{ total + 1 }}/{{ queryList.length }}</div>
            <div class="iconfont icon-xiayibu" style="font-size: 10vw;flex: 1;text-align: right;" @click="totaland(+1)">
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { queryMenuContent } from "@/apis/home";
import { nextTick } from 'vue';
export default {
  data() {
    return {
      iphone: '',
      queryList: [],
      evenlists: [],
      lcList: [],
      bgimg: '',
      curMenus: '',
      total: 0
    }
  }, created() {
    this.getiphone()
    this.getlist()
  },
  computed: {
    count() {
      return this.$store.state.count
    },
    secondMenuId() {
      return this.$store.state.secondMenuId
    }
  },
  watch: {
    count() {
      // console.log(newvlue)
      this.getlist()
    },
    secondMenuId() {
      this.locateAnchorPoint()
    }
  },
  mounted() {
    this.getlisttitleimg()
  },
  methods: {
    totaland(number) {
      console.log(number, 11);
      if (number == +1) {
        this.total++
        console.log(this.total);
      }
      if (number == -1) {
        this.total--
        console.log(this.total);
      }
    },
    clicktitle(titlevalue) {
      console.log(titlevalue);
      localStorage.setItem('title', JSON.stringify(titlevalue))
      // this.$router.push('/title')
      window.open('/title')
    },
    async getlist() {
      let evenlist = []
      let querysList = []
      const res = await queryMenuContent(this.count)
      this.queryList = res.data.data
      res.data.data.forEach((item) => {
        if (item.menuId == 28) {
          evenlist.push(item)
        } else {
          querysList.push(item)
        }
      });
      this.evenlists = evenlist
      this.queryList = querysList
      this.getlisttitleimg()
      this.locateAnchorPoint()
    },
    getiphone() {
      const userAgent = navigator.userAgent.toLowerCase();
      const re =
        /(ipad|iphone os|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile)/i;
      this.iphone = !re.test(userAgent);
    },
    getlisttitleimg() {
      if (this.iphone) {
        const list = localStorage.getItem('menuList')
        const menuList = JSON.parse(list)
        const tobudiv = document.querySelector('.herder')
        const curMenu = menuList.find(item => item.id == this.count)
        this.curMenus = curMenu
        console.log(curMenu, tobudiv);
        if (this.curMenus !== undefined) {
          tobudiv.style.backgroundImage = `url(${curMenu.imgUrl})`
        }
      } else {
        const list = localStorage.getItem('menuList')
        const menuList = JSON.parse(list)
        const curMenu = menuList.find(item => item.id == this.count)
        console.log(curMenu);
        if (curMenu !== undefined) {
          this.bgimg = curMenu.imgUrl
        }
      }
    },
    locateAnchorPoint() {
      if (this.secondMenuId !== null && this.secondMenuId !== undefined) {
        nextTick(() => {
          location.href = `#${this.secondMenuId}`
        })
      }
    }
  }
}
</script>
<style scoped>
.title_text {
  margin: 2vw 0;
  font-size: 2vw;
  font-weight: 700;
}

@keyframes height-transition {
  0% {
    height: 0;
  }

  100% {
    height: 55vw;
  }
}

@keyframes texttop {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.flex {
  flex-direction: row-reverse;
}

.herder {
  width: 100%;
  position: relative;
  /* background-image: url('https://official.beneunder.com/img/home_banner.80fec053.jpg'); */
  height: 500px;
  background-size: cover;
  background-position: 50%;
}

.brand-story {
  margin-top: 6vw;
  padding: 0 13vw;
  /* display: flex; */
  /* justify-content: center; */
}

.hrand-text>div {
  height: 2.5vw;
  text-align: right;
  font-weight: 400;
  font-size: .8vw;
  letter-spacing: 1px;
}

.hrand-text {
  margin-right: 10vw;
  width: 31vw;
  height: 32vw;
}

.hrand-title {
  font-size: 2vw;
  font-weight: 700;
  margin-bottom: 2.6vw;
  color: #000;
  text-align: right;
}

.hrand-imgs {
  position: relative;
  z-index: -1;
  bottom: -740px;
  left: 144px;
}

.heand-image {
  display: flex;
  position: relative;
}

.logo-text {
  display: flex;
  justify-content: space-between;
  margin: 12vw 0;
}

.logo-text:nth-child(2n) {
  flex-direction: row-reverse;
}

/* .text {
  padding-top: 13vw;
} */

.text>div {
  font-size: .8vw;
  font-weight: 400;
  text-align: justify;
  line-height: 2.18vw;
  word-break: break-all;
  width: 33vw;
  height: 17vw;
}

.gainian {
  display: flex;
  justify-content: space-between;
}

.gainina-rigth {
  width: 28vw;
  padding-top: 10vw;
}

.gainina-rigth-text {
  font-size: .8vw;
  font-weight: 400;
  line-height: 2.18vw;
  word-break: break-all;
  text-align: justify;
}

.licheng {
  display: flex;
  justify-content: space-between;
  margin-top: 8vw;
}

.licheng-box {
  display: flex;
  margin-bottom: 3.12vw;
}

.licheng-box-text {
  font-size: .7vw;
  font-weight: 400;
  text-align: justify;
  word-break: break-all;
}

.xian {
  background-color: #ececec;
  width: .3vw;
  height: 55vw;
  position: absolute;
  left: .3333vw;
  z-index: -2;
  animation: height-transition 8s;
  /* height: 100%; */
}

.dibu {
  /* padding-top:3vw; */
  background-color: #ebeef1;
  display: flex;
  justify-content: space-between;
  padding: 1.5vw 15vw;
  border-bottom: 2px solid #d3d6d8;
}

.dibu-left {
  display: flex;
  justify-content: space-between;
}

/* .dibu-right {
    /* display:flex; */
/* } */
.lxfs {
  display: flex;
  justify-content: space-around;
}

.lxfs>div {
  font-size: .8vw;
}

.dibu-right-text {
  font-size: .7vw;
  line-height: .8vw;
  font-weight: 700;
  margin-bottom: 2.08vw;
  margin-right: 7vw;
}
</style>
