<template>
    <div>
        <div v-if="iphone">
            <div class="img-index">
            </div>
            <div class="brand-story">
                <div class="logo-text" :id="item.menuId" v-for="(item, index) in queryList" :key="item.id"
                    :style="index == 1 ? 'flex-direction: row-reverse;' : 'text-align:right;'">
                    <div class="text">
                        <ul style="font-size: 2vw;font-weight: 700;margin-bottom: 2.6vw;margin-top: 2.6vw;">{{
                            item.menuTitle }}</ul>
                        <div :style="index == 1 ? '' : 'text-align:right;flex:1;'" v-if="item.title == null"
                            v-html="item.menuContent" class="wow animate__fadeInUp animate__animated ">
                        </div>
                    </div>
                    <div :style="item.imgUrl == null ? 'display: none;' : ''"
                        class="wow animate__animated animate__fadeInRight" style="width: 29vw;height: 44vw;"><img
                            style="width: 29vw;height: 44vw;" :src="item.imgUrl" alt="">
                    </div>
                </div>
            </div>
            <!-- <div class="shenti">
                <div class="animate__fadeInUp wow "
                    style="display: flex;justify-content: space-between;padding-bottom: 4vw;padding-top: 8vw;animation: bottomTop 1s;">
                    <div style="font-size: 2vw;font-weight: 700;line-height: 2vw;">官方上线平台</div>
                    <div style="font-size: 2vw;font-weight: 200;line-height: 2vw;">Online Store</div>
                </div>
                <div class="panelss">
                    <div class=" wow animate__fadeInUp"
                        style="margin: 1vw;font-size: 1.6vw;font-weight: 700;animation: bottomTop 1s;">
                        国内社交媒体</div>
                    <div class=" wow animate__fadeInUp"
                        style="display: flex;justify-content: space-around;animation: bottomTop 1s;">
                        <ul>
                            <li class="li-icon iconfont icon-tianmao"></li>
                            <li class="li-text" href="">天猫</li>
                        </ul>
                        <ul>
                            <li class="li-icon iconfont icon-pay-jingdong"></li>
                            <li class="li-text" href="">京东</li>
                        </ul>
                        <ul>
                            <li class="li-icon iconfont icon-weipinhui" href=""></li>
                            <li class="li-text" href="">唯品会</li>
                        </ul>
                        <ul>
                            <li class="li-icon iconfont icon-weixinxiaochengxu" href=""></li>
                            <li class="li-text" href=""> 微信小程序</li>
                        </ul>
                    </div>
                </div>
                <div class="panelss">
                    <div class=" wow animate__fadeInUp" style="margin: 1vw 0;font-size: 1.6vw;animation: bottomTop 1s;">
                        海外社交媒体
                    </div>
                    <div class=" wow animate__fadeInUp"
                        style="display: flex;justify-content: space-around;animation: bottomTop 1s;">
                        <ul>
                            <li class="li-icon iconfont icon-tianmao"></li>
                            <li class="li-text" href="">天猫</li>
                        </ul>
                        <ul>
                            <li class="li-icon iconfont icon-pay-jingdong"></li>
                            <li class="li-text" href="">京东</li>
                        </ul>
                        <ul>
                            <li class="li-icon iconfont icon-weipinhui" href=""></li>
                            <li class="li-text" href="">唯品会</li>
                        </ul>
                        <ul>
                            <li class="li-icon iconfont icon-weixinxiaochengxu" href=""></li>
                            <li class="li-text" href=""> 微信小程序</li>
                        </ul>
                    </div>
                </div>
                <div class=" wow animate__fadeInUp"
                    style="display: flex;justify-content: space-between;padding-bottom: 4vw;padding-top: 8vw;animation: bottomTop 1s;">
                    <div style="font-size: 2vw;font-weight: 700;line-height: 2vw;">联络方式</div>
                    <div style="font-size: 2vw;font-weight: 200;line-height: 2vw;">Contact Information</div>
                </div>
                <div class="contact-box">
                    <div class="contact-item  wow animate__fadeInUp" style="animation: bottomTop 1s;">
                        <div class="item-box">
                            <div style="font-size: 15px;margin-right: 10px;" class="iconfont icon-tianmao"></div>
                            <div>市场/内容合作</div>
                        </div>
                        <div class="item-box">cooperation@janezt.com</div>
                    </div>
                    <div class="contact-item  wow animate__fadeInUp" style="animation: bottomTop 1s;">
                        <div class="item-box">
                            <div style="font-size: 15px;margin-right: 10px;" class="iconfont icon-tianmao"></div>
                            <div>市场/内容合作</div>
                        </div>
                        <div class="item-box">cooperation@janezt.com</div>
                    </div>
                </div>
                <div class="contact-box" style="margin: 0;padding-bottom: 10vw;">
                    <div class="contact-item  wow animate__fadeInUp" style="border-top: 0;animation: bottomTop 1s;">
                        <div class="item-box">
                            <div style="font-size: 15px;margin-right: 10px;" class="iconfont icon-tianmao"></div>
                            <div>市场/内容合作</div>
                        </div>
                        <div class="item-box">cooperation@janezt.com</div>
                    </div>
                    <div class="contact-item  wow animate__fadeInUp" style="border-top: 0;animation: bottomTop 1s;">
                        <div class="item-box">
                            <div style="font-size: 15px;margin-right: 10px;" class="iconfont icon-tianmao"></div>
                            <div>市场/内容合作</div>
                        </div>
                        <div class="item-box">cooperation@janezt.com</div>
                    </div>
                </div>
            </div> -->
            <HMbottom></HMbottom>
        </div>
        <div v-else style="background-color: #ebeef1;">
            <div>
                <img style=" width: 100%;position: relative;"
                    src="https://official.beneunder.com/img/h5_contact_banner.52c172b7.jpg" alt="">
                <div style="position: absolute;top: 45%;z-index: 22;text-align: center;color: #ffffff;width: 100%;">
                    <div style="font-size: 8.5vw;font-weight: 700;">Contact Us</div>
                    <div style="font-weight: 300;margin-top: 2.6vw;font-size: 3.7vwc;">联 / 系 / 我 / 们</div>
                </div>
            </div>
            <div style="background: #f4f7fa;padding: 0 4.8vw;">
                <div v-for="(item) in queryList" :key="item.id" style="margin-top: 12.8vw;padding: 0 4.2vw;">
                    <div style="font-size: 5.3vw;font-weight: 700;margin-bottom: 4.26vw;">{{
                        item.menuTitle }}</div>
                    <div v-html="item.menuContent"
                        style="font-weight: 300;font-size: 3.2vw;line-height: 4.3vw;margin-bottom: 4.26vw;letter-spacing: .13333333vw;">
                    </div>
                    <div :style="item.imgUrl == null ? 'display:none;' : ''">
                        <img style="width: 100%;" :src="item.imgUrl" alt="">
                    </div>
                </div>
                <!-- <div style="display: flex;padding-top: 12.8vw;margin-bottom: 4.2vw;justify-content: space-between;">
                    <div style="font-size: 5.3vw;line-height: 5.3vw;font-weight: 700;">社交媒体</div>
                    <div style="font-size: 5.3vw;line-height: 5.3vw;font-weight: 200;">Social Media</div>
                </div>
                <div style="padding: 8.5vw 6.4vw;background-color: #ffffff;">
                    <div style="font-weight: 700;font-size: 4.26vw;line-height: 4.2vw;">国内社交媒体</div>
                    <div style="display: flex;flex-wrap: wrap;justify-content: center;">
                        <ul
                            style="width: 50%;margin-top: 8.3vw;    display: flex;flex-direction: column;align-items: center;">
                            <li class="li-icon iconfont icon-tianmao"></li>
                            <li href="" style="font-size:3.2vw;line-height: 4vw;margin-top: 2.13vw;font-weight: 400;">天猫
                            </li>
                        </ul>
                        <ul
                            style="width: 50%;margin-top: 8.3vw;    display: flex;flex-direction: column;align-items: center;">
                            <li class="li-icon iconfont icon-tianmao"></li>
                            <li href="" style="font-size:3.2vw;line-height: 4vw;margin-top: 2.13vw;font-weight: 400;">天猫
                            </li>
                        </ul>
                        <ul
                            style="width: 50%;margin-top: 8.3vw;    display: flex;flex-direction: column;align-items: center;">
                            <li class="li-icon iconfont icon-tianmao"></li>
                            <li href="" style="font-size:3.2vw;line-height: 4vw;margin-top: 2.13vw;font-weight: 400;">天猫
                            </li>
                        </ul>
                        <ul
                            style="width: 50%;margin-top: 8.3vw;    display: flex;flex-direction: column;align-items: center;">
                            <li class="li-icon iconfont icon-tianmao"></li>
                            <li href="" style="font-size:3.2vw;line-height: 4vw;margin-top: 2.13vw;font-weight: 400;">天猫
                            </li>
                        </ul>
                    </div>
                    <div
                        style="color: rgba(0,0,0,.6);text-align: center;font-weight: 300;line-height: 4vw;font-size: 2.6vw;margin-top: 2vw;">
                        *中国内地（大陆）均可购买到蕉下正品</div>
                </div>
                <div style="padding: 8.5vw 6.4vw;background-color: #ffffff;margin-top: 3.2vw;">
                    <div style="font-weight: 700;font-size: 4.26vw;line-height: 4.2vw;">海外社交媒体</div>
                    <div
                        style="color: rgba(0,0,0,.6);text-align: center;font-weight: 300;line-height: 4vw;font-size: 2.6vw;margin-top: 2vw;">
                        联系邮箱：support@beneunder.com</div>
                    <div style="display: flex;flex-wrap: wrap;justify-content: center;">
                        <ul
                            style="width: 50%;margin-top: 8.3vw;    display: flex;flex-direction: column;align-items: center;">
                            <li class="li-icon iconfont icon-tianmao"></li>
                            <li href="" style="font-size:3.2vw;line-height: 4vw;margin-top: 2.13vw;font-weight: 400;">天猫
                            </li>
                        </ul>
                        <ul
                            style="width: 50%;margin-top: 8.3vw;    display: flex;flex-direction: column;align-items: center;">
                            <li class="li-icon iconfont icon-tianmao"></li>
                            <li href="" style="font-size:3.2vw;line-height: 4vw;margin-top: 2.13vw;font-weight: 400;">天猫
                            </li>
                        </ul>
                        <ul
                            style="width: 50%;margin-top: 8.3vw;    display: flex;flex-direction: column;align-items: center;">
                            <li class="li-icon iconfont icon-tianmao"></li>
                            <li href="" style="font-size:3.2vw;line-height: 4vw;margin-top: 2.13vw;font-weight: 400;">天猫
                            </li>
                        </ul>
                        <ul
                            style="width: 50%;margin-top: 8.3vw;    display: flex;flex-direction: column;align-items: center;">
                            <li class="li-icon iconfont icon-tianmao"></li>
                            <li href="" style="font-size:3.2vw;line-height: 4vw;margin-top: 2.13vw;font-weight: 400;">天猫
                            </li>
                        </ul>
                    </div>
                    <div
                        style="color: rgba(0,0,0,.6);text-align: center;font-weight: 300;line-height: 4vw;font-size: 2.6vw;margin-top: 2vw;">
                        *港澳台及海外地区均可购买到蕉下正品
                        Supply authentic Beneunder products to Hong Kong, Macao, Taiwan and overseas regions</div>
                </div> -->
            </div>
            <!-- <div style="margin: 0 4.8vw;background-color: #ebeef1;">
                <div style="display: flex;justify-content: space-between;margin-top: 12.8vw;margin-bottom: 4.2vw;">
                    <div style="font-weight: 700;font-size: 5vw;line-height: 5vw;">联络方式</div>
                    <div style="font-size: 5vw;line-height: 5vw;font-weight: 200;">Contact Information</div>
                </div>
                <div>
                    <div style="display: flex;border-top: 1px solid rgba(0,0,0, .1);padding: 4.2vw 0;">
                        <div style="width: 8.5vw;height: 8.5vw;margin-right: 4.2vw;" class="iconfont icon-weixin"></div>
                        <div>
                            <div>市场/内容合作</div>
                            <div style="font-size: 3.7vw;font-weight: 700;margin-top: 2vw;line-height: 5vw;">
                                cooperation@janezt.com</div>
                        </div>
                    </div>
                    <div style="display: flex;border-top: 1px solid rgba(0,0,0, .1);padding: 4.2vw 0;">
                        <div style="width: 8.5vw;height: 8.5vw;margin-right: 4.2vw;" class="iconfont icon-weixin"></div>
                        <div>
                            <div>市场/内容合作</div>
                            <div style="font-size: 3.7vw;font-weight: 700;margin-top: 2vw;line-height: 5vw;">
                                cooperation@janezt.com</div>
                        </div>
                    </div>
                    <div style="display: flex;border-top: 1px solid rgba(0,0,0, .1);padding: 4.2vw 0;">
                        <div style="width: 8.5vw;height: 8.5vw;margin-right: 4.2vw;" class="iconfont icon-weixin"></div>
                        <div>
                            <div>市场/内容合作</div>
                            <div style="font-size: 3.7vw;font-weight: 700;margin-top: 2vw;line-height: 5vw;">
                                cooperation@janezt.com</div>
                        </div>
                    </div>
                    <div style="display: flex;border-top: 1px solid rgba(0,0,0, .1);padding: 4.2vw 0;">
                        <div style="width: 8.5vw;height: 8.5vw;margin-right: 4.2vw;" class="iconfont icon-weixin"></div>
                        <div>
                            <div>市场/内容合作</div>
                            <div style="font-size: 3.7vw;font-weight: 700;margin-top: 2vw;line-height: 5vw;">
                                cooperation@janezt.com</div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div style="padding:15.6vw 5vw ;background-color: #f4f7fa;">
                <div style="display: flex;margin: 6vw  0;justify-content: space-between;border-radius: 1px solid #d3d6d8;">
                    <div style="font-size: 4vw;color:#000;font-weight: 700;">社交媒体</div>
                    <div>></div>
                </div>
                <div style="display: flex;margin: 6vw  0;justify-content: space-between;border-radius: 1px solid #d3d6d8;">
                    <div style="font-size: 4vw;color:#000;font-weight: 700;">联络方式</div>
                    <div>></div>
                </div>
                <div
                    style="padding: 0 15vw;font-size: 3.5vw;line-height: 5.3vw;font-weight: 400;color: rgba(0,0, 0, .3);text-align: center;">
                    © 2022 Beneunder 深圳减字科技有限公司.版权所有 <br>
                    粤ICP备15047322号
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { queryMenuContent } from '@/apis/home'
export default {
    name: 'contactIndex',
    data() {
        return {
            iphone: '',
            queryList: []
        }
    },
    created() {
        this.getiphone()
    },
    mounted() {
        this.getlisttitleimg()
    },
    computed: {
        count() {
            return this.$store.state.count
        }
    },
    watch: {
        count(newvalue) {
            if (newvalue == 16) {
                this.getlist()
            }
        }
    },
    methods: {
        async getlist() {
            const res = await queryMenuContent(this.count)
            this.queryList = res.data.data
            console.log(res.data.data);

        },
        getiphone() {
            const userAgent = navigator.userAgent.toLowerCase();
            const re =
                /(ipad|iphone os|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile)/i;
            this.iphone = !re.test(userAgent);
            console.log(this.iphone);
        },
        getlisttitleimg() {
            const list = localStorage.getItem('menuList')
            const menuList = JSON.parse(list)
            const tobudiv = document.querySelector('.img-index')
            tobudiv.style.backgroundImage = `url(${menuList[4].imgUrl})`
        }
    }
}
</script>
<style>
@keyframes bottomTop {
    from {
        opacity: 0;
        transform: translateY(00%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.brand-story {
    margin-top: 10vw;
    padding: 0 13vw;
    /* display: flex; */
    /* justify-content: center; */
}

.logo-text {
    display: flex;
    justify-content: space-between;
    margin: 5vw 0;
}

.text>div {
    font-size: .8vw;
    font-weight: 400;
    text-align: justify;
    line-height: 2.18vw;
    word-break: break-all;
    /* width: 33vw; */
    height: 17vw;
}

.item-box {
    display: flex;
    align-items: center;
}

.contact-item {
    /* flex-direction: row; */
    /* flex-wrap: wrap; */
    width: 31.25vw;
    height: 5.6vw;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, .1);
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.contact-box {
    font-size: .8vw;
    margin-top: 4.6vw;
    display: flex;
    justify-content: space-between;
}

li {
    flex: 1;
}

.iconfont {
    font-size: 25px;
}

.li-icon {
    width: 7.3vw;
    height: 7.3vw;
    border-radius: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 45px;
}

.li-text {
    /* text-align: center; */
    /* margin-top: 2vw; */
    list-style-type: none;
    font-size: .9vw;
}

.panelss {
    margin-top: 5px;
    border-radius: 16px;
    background-color: #ffffff;
    padding: 3vw 3.6vw 2vw 3.6vw;
}

.shenti {
    background-color: #f4f7fa;
    padding: 0 15vw;
}

.img-text {
    text-align: center;
    color: #ffffff;
    line-height: 100vh;
    font-size: 5.2vw;
    font-weight: 700;
}

.img-index {
    background-image: url(https://official.beneunder.com/img/contact_banner.f14e9a27.jpg);
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
}
</style>