<template>
    <div>
        <div v-if="iphone">
            <div class="tobu">
                <!-- <div class="tobu-text wow animate__fadeInUp" style="animation: bottomTop 1s;">Brand Shop</div> -->
            </div>
            <div class="brand-story">
                <div class="logo-text" :id="item.menuId" v-for="(item, index) in queryList" :key="item.id"
                    :style="index == 1 ? 'flex-direction: row-reverse;' : 'text-align:right;'">
                    <div class="text">
                        <ul style="font-size: 2vw;font-weight: 700;margin-bottom: 2.6vw;margin-top: 2.6vw;">{{
                            item.menuTitle }}</ul>
                        <div :style="index == 1 ? '' : 'text-align:right;flex:1;'" v-if="item.title == null"
                            v-html="item.menuContent" class="text_title wow animate__fadeInUp animate__animated ">
                        </div>
                    </div>
                    <div class="wow animate__animated animate__fadeInRight" style="width: 29vw;height: 44vw;"><img
                            style="width: 29vw;height: 44vw;" :src="item.imgUrl" alt="">
                    </div>
                </div>
            </div>
            <!-- <div class="shenti">
                <div class="wow animate__fadeInUp"
                    style="display: flex;justify-content: space-between;padding-bottom: 4vw;padding-top: 8vw;">
                    <div style="font-size: 2vw;font-weight: 700;line-height: 2vw;">官方上线平台</div>
                    <div style="font-size: 2vw;font-weight: 200;line-height: 2vw;">Online Store</div>
                </div>
                <div class="pane333 animate__fadeInUp wow" style="animation: bottomTop 1s;">
                    <div class="wow animate__fadeInUp" style="margin: 1vw 5vw ;font-size: 1.6vw;">
                        中国内地（大陆）</div>
                    <div style="display: flex;justify-content: space-around;">
                        <ul>
                            <li class="li-icon iconfont icon-tianmao"></li>
                            <li class="li-text" href="">天猫</li>
                        </ul>
                        <ul>
                            <li class="li-icon iconfont icon-pay-jingdong"></li>
                            <li class="li-text" href="">京东</li>
                        </ul>
                        <ul>
                            <li class="li-icon iconfont icon-weipinhui" href=""></li>
                            <li class="li-text" href="">唯品会</li>
                        </ul>
                        <ul>
                            <li class="li-icon iconfont icon-weixinxiaochengxu" href=""></li>
                            <li class="li-text" href=""> 微信小程序</li>
                        </ul>
                    </div>
                    <div style="text-align: center;font-size:.8vw;color: #b0a3a1;padding-top: 2vw;">*中国内地（大陆）均可购买到蕉下正品</div>
                </div>
                <div class="pane333 animate__fadeInUp wow" style="animation: bottomTop 1s;">
                    <div style="margin: 1vw 5vw;display: flex;justify-content: space-between;">
                        <div style="font-size: 1.6vw;">港澳台地区及海外</div>
                        <div style="font-size: 0.8vw;">联系邮箱：support@beneunder.com</div>
                    </div>
                    <div style="display: flex;justify-content: space-around;">
                        <ul>
                            <li class="li-icon iconfont icon-tianmao"></li>
                            <li class="li-text" href="">美国独立站</li>
                        </ul>
                        <ul>
                            <li class="li-icon iconfont icon-pay-jingdong"></li>
                            <li class="li-text" href="">美国亚马逊</li>
                        </ul>
                        <ul>
                            <li class="li-icon iconfont icon-weipinhui" href=""></li>
                            <li class="li-text" href="">日本亚马逊</li>
                        </ul>
                    </div>
                    <div style="text-align: center;font-size:.8vw;color: #b0a3a1;padding-top: 2vw;">
                        <div>*港澳台及海外地区均可购买到蕉下正品</div>
                        <div>Supply authentic Beneunder products to Hong Kong, Macao, Taiwan and overseas regions</div>
                    </div>
                </div>
            </div>
            <div class="shenti " style="padding-top: 10px;">
                <div class="wow animate__fadeInUp"
                    style="animation: bottomTop 1s; display: flex;justify-content: space-between;padding-bottom: 4vw;padding-top: 8vw;">
                    <div style="font-size: 2vw;font-weight: 700;line-height: 2vw;">官方线下门店</div>
                    <div style="font-size: 2vw;font-weight: 200;line-height: 2vw;">Offline Store</div>
                </div>
                <div class="wow animate__fadeInUp" style="animation: bottomTop 1s;">
                    <img style="width: 100%;height: 37vw;" src="https://official.beneunder.com/img/brand_store.a400c25e.jpg"
                        alt="">
                </div>
                <div class="wow animate__fadeInUp" style="margin-top: 20px;display: flex;animation: bottomTop 1s;">
                    <div class="address addressActive">华东区</div>
                    <div class="address">华南区</div>
                    <div class="address">华北区</div>
                </div>
                <div class="wow animate__fadeInUp"
                    style="margin-top: 3vw;display: flex;flex-wrap: wrap;animation: bottomTop 1s;">
                    <div class="store-card">
                        <div>上海市-蟠龙天地店</div>
                        <div style="font-size: 0.7vw;font-weight: 300;margin-top: 8px;">上海市黄浦区蟠鼎路123弄1号PL111室蕉下专柜</div>
                    </div>
                    <div class="store-card">
                        <div>上海市-蟠龙天地店</div>
                        <div style="font-size: 0.7vw;font-weight: 300;margin-top: 8px;">上海市黄浦区蟠鼎路123弄1号PL111室蕉下专柜</div>
                    </div>
                    <div class="store-card">
                        <div>上海市-蟠龙天地店</div>
                        <div style="font-size: 0.7vw;font-weight: 300;margin-top: 8px;">上海市黄浦区蟠鼎路123弄1号PL111室蕉下专柜</div>
                    </div>
                    <div class="store-card">
                        <div>上海市-蟠龙天地店</div>
                        <div style="font-size: 0.7vw;font-weight: 300;margin-top: 8px;">上海市黄浦区蟠鼎路123弄1号PL111室蕉下专柜</div>
                    </div>
                    <div class="store-card">
                        <div>上海市-蟠龙天地店</div>
                        <div style="font-size: 0.7vw;font-weight: 300;margin-top: 8px;">上海市黄浦区蟠鼎路123弄1号PL111室蕉下专柜</div>
                    </div>
                    <div class="store-card">
                        <div>上海市-蟠龙天地店</div>
                        <div style="font-size: 0.7vw;font-weight: 300;margin-top: 8px;">上海市黄浦区蟠鼎路123弄1号PL111室蕉下专柜</div>
                    </div>
                    <div class="store-card">
                        <div>上海市-蟠龙天地店</div>
                        <div style="font-size: 0.7vw;font-weight: 300;margin-top: 8px;">上海市黄浦区蟠鼎路123弄1号PL111室蕉下专柜</div>
                    </div>
                    <div class="store-card">
                        <div>上海市-蟠龙天地店</div>
                        <div style="font-size: 0.7vw;font-weight: 300;margin-top: 8px;">上海市黄浦区蟠鼎路123弄1号PL111室蕉下专柜</div>
                    </div>
                </div>
            </div> -->
            <HMbottom></HMbottom>
        </div>
        <div v-else>
            <div>
                <img style="width: 100%;position: relative;"
                    src="https://official.beneunder.com/img/h5_brand_banner.a6f55c2b.jpg" alt="">
                <div style="position: absolute;top: 45%;z-index: 22;text-align: center;color: #ffffff;width: 100%;">
                    <div style="font-size: 8.5vw;font-weight: 700;">Brand Shop</div>
                    <div style="font-weight: 300;margin-top: 2.6vw;font-size: 3.7vwc;">品 / 牌 / 店 / 铺</div>
                </div>
            </div>
            <div style="background: #f4f7fa;padding: 0 4.8vw;">
                <div v-for="(item) in queryList" :key="item.id" style="margin-top: 12.8vw;padding: 0 4.2vw;">
                    <div style="font-size: 5.3vw;font-weight: 700;margin-bottom: 4.26vw;">{{
                        item.menuTitle }}</div>
                    <div v-html="item.menuContent"
                        style="font-weight: 300;font-size: 3.2vw;line-height: 4.3vw;margin-bottom: 4.26vw;letter-spacing: .13333333vw;">
                    </div>
                    <div>
                        <img style="width: 100%;" :src="item.imgUrl" alt="">
                    </div>
                </div>
                <!-- <div style="display: flex;padding-top: 12.8vw;margin-bottom: 4.2vw;justify-content: space-between;">
                    <div style="font-size: 5.3vw;line-height: 5.3vw;font-weight: 700;">官方线上平台</div>
                    <div style="font-size: 5.3vw;line-height: 5.3vw;font-weight: 200;">Online Store</div>
                </div>
                <div style="padding: 8.5vw 6.4vw;background-color: #ffffff;">
                    <div style="font-weight: 700;font-size: 4.26vw;line-height: 4.2vw;">中国内地（大陆）</div>
                    <div style="display: flex;flex-wrap: wrap;justify-content: center;">
                        <ul
                            style="width: 50%;margin-top: 8.3vw;    display: flex;flex-direction: column;align-items: center;">
                            <li class="li-icon iconfont icon-tianmao"></li>
                            <li href="" style="font-size:3.2vw;line-height: 4vw;margin-top: 2.13vw;font-weight: 400;">天猫
                            </li>
                        </ul>
                        <ul
                            style="width: 50%;margin-top: 8.3vw;    display: flex;flex-direction: column;align-items: center;">
                            <li class="li-icon iconfont icon-tianmao"></li>
                            <li href="" style="font-size:3.2vw;line-height: 4vw;margin-top: 2.13vw;font-weight: 400;">天猫
                            </li>
                        </ul>
                        <ul
                            style="width: 50%;margin-top: 8.3vw;    display: flex;flex-direction: column;align-items: center;">
                            <li class="li-icon iconfont icon-tianmao"></li>
                            <li href="" style="font-size:3.2vw;line-height: 4vw;margin-top: 2.13vw;font-weight: 400;">天猫
                            </li>
                        </ul>
                        <ul
                            style="width: 50%;margin-top: 8.3vw;    display: flex;flex-direction: column;align-items: center;">
                            <li class="li-icon iconfont icon-tianmao"></li>
                            <li href="" style="font-size:3.2vw;line-height: 4vw;margin-top: 2.13vw;font-weight: 400;">天猫
                            </li>
                        </ul>
                    </div>
                    <div
                        style="color: rgba(0,0,0,.6);text-align: center;font-weight: 300;line-height: 4vw;font-size: 2.6vw;margin-top: 2vw;">
                        *中国内地（大陆）均可购买到蕉下正品</div>
                </div>
                <div style="padding: 8.5vw 6.4vw;background-color: #ffffff;margin-top: 3.2vw;">
                    <div style="font-weight: 700;font-size: 4.26vw;line-height: 4.2vw;">港澳台地区及海外</div>
                    <div
                        style="color: rgba(0,0,0,.6);text-align: center;font-weight: 300;line-height: 4vw;font-size: 2.6vw;margin-top: 2vw;">
                        联系邮箱：support@beneunder.com</div>
                    <div style="display: flex;flex-wrap: wrap;justify-content: center;">
                        <ul
                            style="width: 50%;margin-top: 8.3vw;    display: flex;flex-direction: column;align-items: center;">
                            <li class="li-icon iconfont icon-tianmao"></li>
                            <li href="" style="font-size:3.2vw;line-height: 4vw;margin-top: 2.13vw;font-weight: 400;">天猫
                            </li>
                        </ul>
                        <ul
                            style="width: 50%;margin-top: 8.3vw;    display: flex;flex-direction: column;align-items: center;">
                            <li class="li-icon iconfont icon-tianmao"></li>
                            <li href="" style="font-size:3.2vw;line-height: 4vw;margin-top: 2.13vw;font-weight: 400;">天猫
                            </li>
                        </ul>
                        <ul
                            style="width: 50%;margin-top: 8.3vw;    display: flex;flex-direction: column;align-items: center;">
                            <li class="li-icon iconfont icon-tianmao"></li>
                            <li href="" style="font-size:3.2vw;line-height: 4vw;margin-top: 2.13vw;font-weight: 400;">天猫
                            </li>
                        </ul>
                        <ul
                            style="width: 50%;margin-top: 8.3vw;    display: flex;flex-direction: column;align-items: center;">
                            <li class="li-icon iconfont icon-tianmao"></li>
                            <li href="" style="font-size:3.2vw;line-height: 4vw;margin-top: 2.13vw;font-weight: 400;">天猫
                            </li>
                        </ul>
                    </div>
                    <div
                        style="color: rgba(0,0,0,.6);text-align: center;font-weight: 300;line-height: 4vw;font-size: 2.6vw;margin-top: 2vw;">
                        *港澳台及海外地区均可购买到蕉下正品
                        Supply authentic Beneunder products to Hong Kong, Macao, Taiwan and overseas regions</div>
                </div>
                <div style="display: flex;margin-top: 12.8vw;margin-bottom: 4.2vw;justify-content: space-between;">
                    <div style="font-size: 5.3vw;line-height: 5.3vw;font-weight: 700;">官方线下门店</div>
                    <div style="font-size: 5.3vw;line-height: 5.3vw;font-weight: 200;">Offline Store</div>
                </div>
                <div>
                    <img style="width: 100%;" src="https://official.beneunder.com/img/brand_store.a400c25e.jpg" alt="">
                </div>
                <div style="margin: 4.26vw 0;display:flex;">
                    <div
                        style="margin:0 1vw;padding: 2.5vw 0;font-size:3.5vw;text-align: center;line-height: 3.5vw;border: 1px solid #000;border-radius: 10px;margin:0 1vw;">
                        华东区
                    </div>
                    <div
                        style="padding: 2.5vw 0;font-size:3.5vw;text-align: center;line-height: 3.5vw;border: 1px solid #000;border-radius: 10px;margin:0 1vw;">
                        华东区
                    </div>
                    <div
                        style="padding: 2.5vw 0;font-size:3.5vw;text-align: center;line-height: 3.5vw;border: 1px solid #000;border-radius: 10px;margin:0 1vw;">
                        华东区
                    </div>
                    <div
                        style="padding: 2.5vw 0;font-size:3.5vw;text-align: center;line-height: 3.5vw;border: 1px solid #000;border-radius: 10px;margin:0 1vw;">
                        华东区
                    </div>
                    <div
                        style="padding: 2.5vw 0;font-size:3.5vw;text-align: center;line-height: 3.5vw;border: 1px solid #000;border-radius: 10px;margin:0 1vw;">
                        华东区
                    </div>
                    <div
                        style="padding: 2.5vw 0 ;font-size:3.5vw;text-align: center;line-height: 3.5vw;border: 1px solid #000;border-radius: 10px;margin:0 1vw;">
                        华东区
                    </div>
                </div>
                <div>
                    <div>
                        <div
                            style="border-radius:8px;padding: 4.2vw;display: flex;flex-direction: column;background-color: #ffffff;margin-top: 2vw;">
                            <div>上海市</div>
                            <div>上海地址</div>
                        </div>
                        <div
                            style="border-radius:8px;padding: 4.2vw;display: flex;flex-direction: column;background-color: #ffffff;margin-top: 2vw;">
                            <div>上海市</div>
                            <div>上海地址</div>
                        </div>
                        <div
                            style="border-radius:8px;padding: 4.2vw;display: flex;flex-direction: column;background-color: #ffffff;margin-top: 2vw;">
                            <div>上海市</div>
                            <div>上海地址</div>
                        </div>
                        <div
                            style="border-radius:8px;padding: 4.2vw;display: flex;flex-direction: column;background-color: #ffffff;margin-top: 2vw;">
                            <div>上海市</div>
                            <div>上海地址</div>
                        </div>
                        <div
                            style="border-radius:8px;padding: 4.2vw;display: flex;flex-direction: column;background-color: #ffffff;margin-top: 2vw;">
                            <div>上海市</div>
                            <div>上海地址</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="padding:15.6vw ;background-color: #ebeef1;">
                <div style="display: flex;margin: 6vw  0;justify-content: space-between;border-radius: 1px solid #d3d6d8;">
                    <div style="font-size: 4vw;color:#000;font-weight: 700;">社交媒体</div>
                    <div>></div>
                </div>
                <div style="display: flex;margin: 6vw  0;justify-content: space-between;border-radius: 1px solid #d3d6d8;">
                    <div style="font-size: 4vw;color:#000;font-weight: 700;">联络方式</div>
                    <div>></div>
                </div>
                <div
                    style="padding: 0 15vw;font-size: 3.5vw;line-height: 5.3vw;font-weight: 400;color: rgba(0,0, 0, .3);text-align: center;">
                    © 2022 Beneunder 深圳减字科技有限公司.版权所有 <br>
                    粤ICP备15047322号
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import { queryMenuContent } from '@/apis/home'
export default {
    name: 'brandIndex',
    data() {
        return {
            iphone: '',
            queryList: []
        }
    },
    created() {
        this.getiphone()
    },
    mounted() {
        this.getlisttitleimg()
    },
    watch: {
        count(newvalue) {
            if (newvalue == 12) {
                this.getlist()
            }
        }
    },
    computed: {
        count() {
            return this.$store.state.count
        }
    },
    methods: {
        async getlist() {
            const res = await queryMenuContent(this.count)
            console.log(res.data.data);
            this.queryList = res.data.data
        },
        getiphone() {
            const userAgent = navigator.userAgent.toLowerCase();
            const re =
                /(ipad|iphone os|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile)/i;
            this.iphone = !re.test(userAgent);
            console.log(this.iphone);
        },
        getlisttitleimg() {
            const list = localStorage.getItem('menuList')
            const menuList = JSON.parse(list)
            const tobudiv = document.querySelector('.tobu')
            tobudiv.style.backgroundImage = `url(${menuList[3].imgUrl})`
        }
    }
}
</script>
<style scoped>
@keyframes bottomTop {
    from {
        opacity: 0;
        transform: translateY(00%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.brand-story {
    margin-top: 10vw;
    padding: 0 13vw;
    /* display: flex; */
    /* justify-content: center; */
}

.logo-text {
    display: flex;
    justify-content: space-between;
    margin: 5vw 0;
}

.text {
    flex: 1;
    padding-right: 5vw;
}

.text>.text_title {
    font-size: .8vw;
    font-weight: 400;
    text-align: justify;
    line-height: 2.18vw;
    word-break: break-all;
    height: 17vw;
}


.dibu {
    /* padding-top:3vw; */
    background-color: #ebeef1;
    display: flex;
    justify-content: space-between;
    padding: 1.5vw 15vw;
    border-bottom: 2px solid #d3d6d8;
}

.dibu-left {
    display: flex;
    justify-content: space-between;
}

/* .dibu-right {
    /* display:flex; */
/* } */
.lxfs {
    display: flex;
    justify-content: space-around;
}

.lxfs>div {
    font-size: .8vw;
}

.dibu-right-text {
    font-size: .7vw;
    line-height: .8vw;
    font-weight: 700;
    margin-bottom: 2.08vw;
    margin-right: 7vw;
}

.store-card {
    width: 11.99vw;
    height: 7vw;
    background: #fff;
    border-radius: 8px;
    padding: 0 1.5vw;
    margin: 0 1.25vw 1.25vw 0;
    display: flex;
    font-size: .9vw;
    font-weight: 600;
    flex-direction: column;
    display: flex;
    justify-content: center;
}

li {
    font-style: normal;
}

.address {
    margin-right: 8px;
    width: 6vw;
    height: 2.5vw;
    line-height: 2.5vw;
    background: #ffffff;
    border-radius: 20px;
    text-align: center;
    border: 1px solid #000;
    font-size: .8vw;
}

.addressActive {
    background: #000;
    color: #ffffff;
}

.li-icon {
    text-align: center;
    padding: 5px;
    list-style-type: none;
    background-color: #f8f8f8;
    border-radius: 50%;
    font-size: 4vw;
}

.li-text {
    text-align: center;
    margin-top: 2vw;
    list-style-type: none;
    font-size: .9vw;
}

li {
    list-style-type: none;
}

.pane333 {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 3vw 3.6vw 2vw 3.6vw;
    margin-top: 1vw;
}

.shenti {
    background-color: #f4f7fa;
    padding: 0 15vw;
}

.tobu-text {
    color: #f4f7fa;
    /* width: 100%; */
    display: flex;
    line-height: 100vh;
    justify-content: center;
    text-align: center;
    font-size: 5vw;
    font-weight: 700;
}

.tobu {
    width: 100%;
    position: relative;
    background-image: url(https://official.beneunder.com/img/brand_banner.1eef0539.jpg);
    height: 500px;
    background-size: cover;
    background-position: 50%;
}
</style>