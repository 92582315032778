<template>
  <div class="about">
    <div v-if="iphone">
      <div class="tobu">
        <!-- <div class="animate__fadeInUp wow" style="
          color: aliceblue;
          text-align: center;
          font-weight: 700;
          line-height: 100vh;
          font-size: 5.8vw;
          animation: bottomTop 1s;
          transition: all 1s;
        ">
          Protection Technology
        </div> -->
      </div>
      <div class="st">
        <div class="logo-text" :id="item.menuId" v-for="(item, index) in     queryList    " :key="item.id"
          :style="index % 2 == 0 ? 'flex-direction: row-reverse;' : 'text-align:right;'">
          <div class="text">
            <ul style="font-size: 2vw;font-weight: 700;margin-bottom: 2.6vw;margin-top: 2.6vw;">{{
              item.menuTitle }}</ul>
            <ul :style="index % 2 == 0 ? '' : 'text-align:right;flex:1;'" v-if="item.title == null"
              v-html="item.menuContent" class="text_title wow animate__fadeInUp animate__animated ">
            </ul>
          </div>
          <div class="wow animate__animated animate__fadeInRight" style="width: 29vw;height: 44vw;"><img
              style="width: 29vw;height: 44vw;" :src="item.imgUrl" alt="">
          </div>
        </div>
        <!-- <div class="st-1">
          <div class="st-text">
            <div class="animate__fadeInUp wow"
              style="font-size: 4vw; line-height: 5.2vw; font-weight: 700; animation: bottomTop 1s;">
              Anti-Cold <br />寒冷防护科技
            </div>
            <div style="display: flex; justify-content: flex-end">
              <div class="animate__fadeInUp wow" style="
                position: absolute;
                bottom: 6vw;
                left: 27vw;
                border: 2px solid aliceblue;
                padding: 5px;
                font-size: 0.8vw;
                animation: bottomTop 1s;
              ">
                Read More
              </div>
              <div class="animate__fadeInUp wow" style="
                font-size: 2vw;
                height: 12.5vw;
                font-weight: 100;
                margin-bottom: 1.04;
                margin-top: 5vw;
                animation: bottomTop 1s;
              ">
                为寒冷研发 <br />
                热力科技 <br />
                持续提供温暖
              </div>
            </div>
          </div>
          <div class="video-box">
            <video class="video-box" autoplay muted loop playsinline src="../assets/1651909751892.mp4"></video>
          </div>
        </div>
        <div class="st-2">
          <div class="xiedian">
            <div class="xiedian-video">
              <video style="width: 28vw; height: 40vw;position: absolute;top: -7vw;left: 20vw;" autoplay muted loop
                playsinline src="../assets/1651893889643.mp4"></video>
            </div>
            <div class="xiedi-text">
              <div>
                <div class="animate__fadeInUp wow"
                  style="font-size: 4.1vw;line-height: 5.2vw;font-weight: 700; animation: bottomTop 1s;">Anti-Strain <br>
                  暴走防护科技</div>
              </div>
              <div style="display: flex;margin-top: 3vw;">
                <div class="animate__fadeInUp wow"
                  style="font-size: 2.08vw;height: 12.5vw;font-weight: 100; animation: bottomTop 1s;">
                  聚焦行走压力 <br>
                  集合两大运动防护黑科技
                </div>
                <div class="animate__fadeInUp wow"
                  style="line-height: 1.5vw;text-align: center;height: 1.5vw;list-style:1.5vw;padding: 3px;border: 2px solid aliceblue;position: absolute;right: 13vw;bottom: 12vw;width: 6.3vw;font-size: 0.8vw; animation: bottomTop 1s; ">
                  Read More
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="st-3">
          <div style="width: 80vw; background-color: #496a43;">
            <div class="st-3-text">
              <div class="animate__fadeInUp wow"
                style="font-size: 4vw; line-height: 5.2vw; font-weight: 700; animation: bottomTop 1s;">
                Anti-Water <br>
                雨水防护科技
              </div>
              <div style="display: flex; justify-content: flex-end">
                <div class="animate__fadeInUp wow" style="
                position: absolute;
                bottom: 9vw;
                left: 22vw;
                border: 2px solid aliceblue;
                padding: 5px;
                font-size: 0.8vw;
                animation: bottomTop 1s;
              ">
                  Read More
                </div>
                <div class="animate__fadeInUp wow" style="
                font-size: 2vw;
                height: 12.5vw;
                font-weight: 100;
                margin-bottom: 1.04;
                margin-top: 5vw;
                animation: bottomTop 1s;
              ">
                  雨水天气 <br>
                  流体污渍 <br>
                  科技加持 <br>
                  轻松应对突发情况
                </div>
              </div>
            </div>
            <div>
              <video class="st-3-video" autoplay muted loop playsinline src="../assets/1651893862858.mp4"></video>
            </div>
          </div>
        </div>
        <div class="st-2">
          <div class="xiedian">
            <div class="xiedian-video">
              <video style="width: 28vw; height: 40vw;position: absolute;top: -7vw;left: 20vw;" autoplay muted loop
                playsinline src="../assets/1651910156223.mp4"></video>
            </div>
            <div class="xiedi-text">
              <div>
                <div class="animate__fadeInUp wow"
                  style="font-size: 4.1vw;line-height: 5.2vw;font-weight: 700; animation: bottomTop 1s;">Anti-UV <br>
                  日晒防护科技</div>
              </div>
              <div style="display: flex;margin-top: 3vw;">
                <div class="animate__fadeInUp wow"
                  style="font-size: 2.08vw;height: 12.5vw;font-weight: 100; animation: bottomTop 1s;">
                  深耕UPF50+ <br>
                  防晒领域 <br>
                  集合3大防晒黑科技
                </div>
                <div class="animate__fadeInUp wow"
                  style="line-height: 1.5vw;text-align: center;height: 1.5vw;list-style:1.5vw;padding: 3px;border: 2px solid aliceblue;position: absolute;right: 13vw;bottom: 12vw;width: 6.3vw;font-size: 0.8vw;  animation: bottomTop 1s;">
                  Read More
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="st-4">
          <div class="st-tex">
            <div class="animate__fadeInUp wow"
              style="font-size: 4vw; line-height: 5.2vw; font-weight: 700; animation: bottomTop 1s;">
              Anti-Cold <br />寒冷防护科技
            </div>
            <div style="display: flex; justify-content: flex-end">
              <div class="animate__fadeInUp wow" style="
                position: absolute;
                bottom: 12vw;
                left: 21vw;
                border: 2px solid aliceblue;
                padding: 5px;
                font-size: 0.8vw;
                animation: bottomTop 1s;
              ">
                Read More
              </div>
              <div class="animate__fadeInUp wow" style="
                font-size: 2vw;
                height: 12.5vw;
                font-weight: 100;
                margin-bottom: 1.04;
                margin-top: 5vw;
                animation: bottomTop 1s;
              ">
                为寒冷研发 <br />
                热力科技 <br />
                持续提供温暖
              </div>
            </div>
          </div>
          <div class="st-4-box">
            <video class="st-4-box" autoplay muted loop playsinline src="../assets/1651909751892.mp4"></video>
          </div>
        </div> -->
      </div>
      <HMbottom></HMbottom>
    </div>
    <div v-else>
      <div>
        <div style="position: relative;">
          <img style="width: 100%;" :src="bgimg" alt="">
          <div style="position: absolute;z-index: 22;top: 50%;text-align: center;width: 100%;color: #ffffff;">
            <div style="font-size: 8.5vw;font-weight: 700;">Protection Technology</div>
            <div style="font-size: 3.7vw;margin-top: 3.6vw;font-weight: 300;letter-spacing: .26vw;">防 / 护 / 科 / 技</div>
          </div>
        </div>
      </div>
      <div v-for="(item) in queryList" :key="item.id" style="margin-top: 12.8vw;padding: 0 4.2vw;">
        <div style="font-size: 5.3vw;font-weight: 700;margin-bottom: 4.26vw;">{{
          item.menuTitle }}</div>
        <div v-html="item.menuContent"
          style="font-weight: 300;font-size: 3.2vw;line-height: 4.3vw;margin-bottom: 4.26vw;letter-spacing: .13333333vw;">
        </div>
        <div>
          <img style="width: 100%;" :src="item.imgUrl" alt="">
        </div>
      </div>
      <!-- <div style="padding: 0 4.2vw;background-color: #f5f5f5;">
        <div style="padding: 4.2vw;background-color: #ffffff;border-radius: 16px;">
          <div style="font-size: 4vw;line-height: 4vw;font-weight: 700;color: #666666;">Anti-Cold</div>
          <div style="font-size: 6.4vw;line-height: 6.4vw;margin-top: 1vw;font-weight: 700;">寒冷防护科技</div>
          <div style="display: flex;">
            <div style="font-weight: 300;font-size: 3.4vw;line-height: 5.3vw;flex: 1;">为寒冷研发热力科技
              持续提供温暖</div>
            <div
              style="padding: 0 4.26vw;font-size: 3.2vw;text-align: center;height: 8.5vw;line-height: 8.5vw; font-weight: 400;border: 1px solid #cccccc;border-radius:32px ;">
              Read More</div>
          </div>
          <div>
            <video style="width: 100%;" autoplay muted loop playsinline src="../assets/1651909751892.mp4"></video>
          </div>
        </div>
        <div style="padding: 4.2vw;background-color: #ffffff;border-radius: 16px;margin-top: 4.26vw;">
          <div style="font-size: 4vw;line-height: 4vw;font-weight: 700;color: #666666;">Anti-Strain</div>
          <div style="font-size: 6.4vw;line-height: 6.4vw;margin-top: 1vw;font-weight: 700;">暴走防护科技</div>
          <div style="display: flex;">
            <div style="font-weight: 300;font-size: 3.4vw;line-height: 5.3vw;flex: 1;">聚焦行走压力
              集合两大运动防护黑科技</div>
            <div
              style="padding: 0 4.26vw;font-size: 3.2vw;text-align: center;height: 8.5vw;line-height: 8.5vw; font-weight: 400;border: 1px solid #cccccc;border-radius:32px ;">
              Read More</div>
          </div>
          <div>
            <video style="width: 100%;" autoplay muted loop playsinline
              src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651893889643.mp4"></video>
          </div>
        </div>
        <div style="padding: 4.2vw;background-color: #ffffff;border-radius: 16px;margin-top: 4.26vw;">
          <div style="font-size: 4vw;line-height: 4vw;font-weight: 700;color: #666666;">Anti-Water</div>
          <div style="font-size: 6.4vw;line-height: 6.4vw;margin-top: 1vw;font-weight: 700;">雨水防护科技</div>
          <div style="display: flex;">
            <div style="font-weight: 300;font-size: 3.4vw;line-height: 5.3vw;flex: 1;">雨水天气、流体污渍
              科技加持轻松应对突发情况</div>
            <div
              style="padding: 0 4.26vw;font-size: 3.2vw;text-align: center;height: 8.5vw;line-height: 8.5vw; font-weight: 400;border: 1px solid #cccccc;border-radius:32px ;">
              Read More</div>
          </div>
          <div>
            <video style="width: 100%;" autoplay muted loop playsinline
              src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651893862858.mp4"></video>
          </div>
        </div>
        <div style="padding: 4.2vw;background-color: #ffffff;border-radius: 16px;margin-top: 4.26vw;">
          <div style="font-size: 4vw;line-height: 4vw;font-weight: 700;color: #666666;">Anti-UV</div>
          <div style="font-size: 6.4vw;line-height: 6.4vw;margin-top: 1vw;font-weight: 700;">日晒防护科技</div>
          <div style="display: flex;">
            <div style="font-weight: 300;font-size: 3.4vw;line-height: 5.3vw;flex: 1;">深耕UPF50+防晒领域
              集合3大防晒黑科技</div>
            <div
              style="padding: 0 4.26vw;font-size: 3.2vw;text-align: center;height: 8.5vw;line-height: 8.5vw; font-weight: 400;border: 1px solid #cccccc;border-radius:32px ;">
              Read More</div>
          </div>
          <div>
            <video style="width: 100%;" autoplay muted loop playsinline
              src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651910156223.mp4"></video>
          </div>
        </div>
        <div style="padding: 4.2vw;background-color: #ffffff;border-radius: 16px;margin-top: 4.26vw;">
          <div style="font-size: 4vw;line-height: 4vw;font-weight: 700;color: #666666;">Anti-Hot</div>
          <div style="font-size: 6.4vw;line-height: 6.4vw;margin-top: 1vw;font-weight: 700;">炎热防护科技</div>
          <div style="display: flex;">
            <div style="font-weight: 300;font-size: 3.4vw;line-height: 5.3vw;flex: 1;">抵抗户外炎热
              集合两大凉爽体感科技</div>
            <div
              style="padding: 0 4.26vw;font-size: 3.2vw;text-align: center;height: 8.5vw;line-height: 8.5vw; font-weight: 400;border: 1px solid #cccccc;border-radius:32px ;">
              Read More</div>
          </div>
          <div>
            <video style="width: 100%;" autoplay muted loop playsinline
              src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651893816388.mp4"></video>
          </div>
        </div>
        <div style="padding:4.8vw ;background-color: #ebeef1;">
          <div style="display: flex;margin: 6vw  0;justify-content: space-between;border-radius: 1px solid #d3d6d8;">
            <div style="font-size: 4vw;color:#000;font-weight: 700;">社交媒体</div>
            <div>></div>
          </div>
          <div style="display: flex;margin: 6vw  0;justify-content: space-between;border-radius: 1px solid #d3d6d8;">
            <div style="font-size: 4vw;color:#000;font-weight: 700;">联络方式</div>
            <div>></div>
          </div>
          <div
            style="padding: 0 15vw;font-size: 3.5vw;line-height: 5.3vw;font-weight: 400;color: rgba(0,0, 0, .3);text-align: center;">
            © 2023 深圳深之旅国际旅行社有限公司.版权所有 <br>
            粤ICP备2023031027号
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<style lang="less" scoped>
@keyframes bottomTop {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.text {
  flex: 1;
  padding-right: 5vw;
}

.text_title {
  font-size: .8vw;
  font-weight: 400;
  text-align: justify;
  line-height: 2.18vw;
  word-break: break-all;
  // width: 33vw;
  height: 17vw;
}

.brand-story {
  margin-top: 10vw;
  padding: 0 13vw;
  /* display: flex; */
  /* justify-content: center; */
}

.logo-text {
  display: flex;
  justify-content: space-between;
  margin: 5vw;
}

.dibu {
  /* padding-top:3vw; */
  background-color: #ebeef1;
  display: flex;
  justify-content: space-between;
  padding: 1.5vw 15vw;
  border-bottom: 2px solid #d3d6d8;
}

.dibu-left {
  display: flex;
  justify-content: space-between;
}

/* .dibu-right {
    /* display:flex; */
/* } */
.lxfs {
  display: flex;
  justify-content: space-around;
}

.lxfs>div {
  font-size: .8vw;
}

.dibu-right-text {
  font-size: .7vw;
  line-height: .8vw;
  font-weight: 700;
  margin-bottom: 2.08vw;
  margin-right: 7vw;
}

.st-4-box {
  position: absolute;
  top: -2.8vw;
  right: 2vw;
  width: 28vw;
  height: 40vw;
  object-fit: cover;
}

.st-tex {
  /* position: relative; */
  /* background-color: #496a43; */
  /* width: 80%; */
  text-align: right;
  width: 34vw;
  color: aliceblue;
  position: relative;
  padding: 7vw;
}

.st-4 {
  position: relative;
  margin-top: 15vw;
  width: 80%;
  background-color: #496a43;
}

.st-3-text {
  text-align: right;
  width: 40vw;
  color: aliceblue;
  position: relative;
  padding: 7vw;
}

.st-3-video {
  position: absolute;
  top: -5.8vw;
  right: 22vw;
  width: 28vw;
  height: 40vw;
  object-fit: cover;
}

.st-3 {
  display: flex;
  width: 100%;
  position: relative;
  margin-top: 15vw;
}

.xiedi-text {
  padding: 5.5vw 0 5vw 10vw;
  color: #fff;
}

.xiedian-video {
  width: 28vw;
  height: 40vw;
}

.xiedian {
  width: 84vw;
  height: 36vw;
  display: flex;
  background-color: #496a43;
  /* justify-content: flex-end; */
}

.st-2 {
  margin-top: 12vw;
  display: flex;
  width: 100%;
  /* height: 36vw; */
  position: relative;
  justify-content: flex-end;
  /* background-color: #496a43; */
}

.video-box {
  position: absolute;
  top: -4.8vw;
  right: 3vw;
  width: 28vw;
  height: 40vw;
  object-fit: cover;
}

.st-text {
  text-align: right;
  width: 40vw;
  color: aliceblue;
  position: relative;
  padding: 7vw;
}

.st-1 {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #496a43;
  width: 84vw;
  height: 36vw;
}

.st {
  margin-top: 12vw;
  margin-bottom: 12vw;
}

.tobu {
  position: relative;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>
<script>
import { queryMenuContent } from "@/apis/home";
export default {
  data() {
    return {
      iphone: '',
      queryList: [],
      imglist: [],
      bgimg: ''
    }
  },
  created() {
    this.ifiphone()
    this.getlist()
  },
  mounted() {
    this.getlisttitleimg()
  },
  computed: {
    count() {
      return this.$store.state.count
    }
  },
  watch: {
    count() {
      // if (newvlue == 6) {
      //   this.getlist()
      // }
      this.getlist()
    }
  },
  methods: {
    async getlist() {
      const res = await queryMenuContent(this.count)
      console.log(this.count);
      this.queryList = res.data.data
      console.log(res.data.data);
    },
    ifiphone() {
      const userAgent = navigator.userAgent.toLowerCase();
      const re =
        /(ipad|iphone os|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile)/i;
      this.iphone = !re.test(userAgent);
      // console.log(re.test(userAgent));
    },
    getlisttitleimg() {
      if (this.iphone) {
        const list = localStorage.getItem('menuList')
        const menuList = JSON.parse(list)
        const tobudiv = document.querySelector('.tobu')
        tobudiv.style.backgroundImage = `url(${menuList[1].imgUrl})`
      } else {
        const list = localStorage.getItem('menuList')
        const menuList = JSON.parse(list)
        this.bgimg = menuList[1].imgUrl
      }
    }
  }
}
</script>
