
<template>
    <div v-if="iphone" class="tobu">
        <video style="height: 100vh;width: 100%;object-fit: cover;" autoplay muted loop playsinline
            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651907739599.mp4"></video>
        <div class="mask"></div>
        <div class="title  wow animate__fadeInUp" style="animation: bottomTop 1s;">Anti-Cold
            寒冷防护科技</div>
        <div class="panel">
            <div style="padding-right: 50vw;font-size: 2.5vw;font-weight: 700;line-height: 3vw;">寒冷防护科技</div>
            <div style="margin-top: .5vw; font-size: 1vw;font-weight: 200;line-height: 1.5vw;">HeatLock · 锁温蓄热 · 轻盈保暖</div>
        </div>
        <div class="content">
            <div style="display: flex;justify-content: space-between; padding: 0 15vw;">
                <div class="content-left">
                    <div class="name">
                        蓄暖升温黑科技
                    </div>
                    <div style="font-size: .8vw;font-weight: 200;line-height: 1.5vw;">
                        寒冷防护科技由四重蓄暖原理组成， <br>
                        根据面料特性分为科技纤维导热蓄暖、 <br>
                        皮肤呼吸湿气热能转换、远红外蓄暖、 <br>
                        高密度绒毛空气锁温四种不同保暖方式， <br>
                        四种方式均能减少热量散失，持久蓄热， <br>
                        使得身体处于寒冷环境下仍能保持温暖感受。 <br>
                    </div>
                </div>
                <div class="content-right">
                    <div class="content-img"></div>
                </div>
            </div>
            <div
                style="display: flex; font-size:.8vw;text-align: center;flex-direction: row-reverse;padding: 0 15vw 5vw 0;">
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-box"
                            src="	https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1666769552861.jpg" alt="">
                    </div>
                    <span style="margin-top: 2vw;">挚暖系列摇粒绒打底衫</span>
                </div>
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-box"
                            src="	https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1666769536442.jpg" alt="">
                    </div>
                    <span style="margin-top: 2vw;">暖霁系列纷云薄款短款外套</span>
                </div>
                <div style="margin-right: 2vw;">
                    <div class="img-box">
                        <img class="img-box"
                            src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1666769548607.jpg" alt="">
                    </div>
                    <span style="margin-top: 2vw;">炽煦系列短款羽绒服</span>
                </div>
            </div>
        </div>
        <div class="dibu">
            <div>
                <div style="font-size: .7vw;line-height: .8vw;letter-spacing: 1px;font-weight: 700;margin-bottom: 2.08vw;">
                    联系我们
                </div>
                <div class="dibu-left" style="font-size: .7vw;line-height: .8vw;letter-spacing: 1px;margin-bottom: 2.08vw;">
                    <div style="color:#464748;">
                        <div style="margin-bottom: 1vw;">商务合作 <span style="margin: 0 10px;">cooperation@janezt.com</span>
                        </div>
                        <div>加入我们 <span style="margin: 0 10px;">hr@janezt.com</span> </div>
                    </div>
                    <div style="color:#464748;padding-left: 7vw;">
                        <div style="margin-bottom: 1vw;">联系电话 <span>0755-84532595</span></div>
                        <div>公司地址 <span>深圳市南山区粤海街道深大南大道9968号汉京金融中心14楼</span> </div>
                    </div>
                </div>
            </div>
            <div class="dibu-right">
                <div class="dibu-right-text">关注我们</div>
                <div class="lxfs">
                    <div class="iconfont icon-weibo"></div>
                    <div class="iconfont icon-weixin"></div>
                    <div class="iconfont icon-douyin"></div>
                    <div class="iconfont icon-xiaohongshu1"></div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div>
            <video style="width: 100%;height: 100vh;object-fit: cover;position: absolute;" autoplay muted loop playsinline
                src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651909751892.mp4"></video>
            <div style="background-color: rgba(0, 0, 0, .6);width: 100%;height: 100vh;z-index: 22;position: relative;">
                <div style="position: absolute;width: 100%;text-align: center;top: 50%;color: #ffffff;">
                    <div style="font-weight: 700;font-size: 8.5vw;">寒冷防护科技</div>
                    <div style="font-weight: 300;font-size: 4.2vw;">Anti-Cold </div>
                </div>
            </div>
        </div>
        <div style="margin-top: 8.5vw;padding: 4.2vw;">
            <div style="font-size: 4.8vw;font-weight: 700;line-height: 5.3vw;">寒冷防护科技</div>
            <div style="font-size: 3.5vw;margin-top: 2.1vw;">HeatLock · 锁温蓄热 · 轻盈保暖</div>
        </div>
        <div style="margin-top: 6.4vw;padding: 0 4.2vw;">
            <div style="font-size: 3.7vw;font-weight: 700;">蓄暖升温黑科技</div>
            <div style="font-size: 3.2vw;font-weight: 200;margin-top: 2.1vw;">
                寒冷防护科技由四重蓄暖原理组成，根据面料特性分为科技纤维导热蓄暖、皮肤呼吸湿气热能转换、远红外蓄暖、高密度绒毛空气锁温四种不同保暖方式，四种方式均能减少热量散失，持久蓄热，使得身体处于寒冷环境下仍能保持温暖感受。
            </div>
            <div style="margin-top: 3.5vw;">
                <img style="width: 100%;height: 63.6vw;"
                    src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1652247737335.jpg" alt="">
            </div>
            <div style="display: flex;width: 100%;">
                <div style="flex: 1;">
                    <img style="width: 100%;"
                        src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1666769552861.jpg" alt="">
                    <div>挚暖系列摇粒绒打底衫</div>
                </div>
                <div style="flex: 1;">
                    <img style="width: 100%;"
                        src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1666769536442.jpg" alt="">
                    <div>暖霁系列纷云薄款短款外套</div>
                </div>
                <div style="flex: 1;">
                    <img style="width: 100%;"
                        src="https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1666769548607.jpg" alt="">
                    <div>炽煦系列短款羽绒服</div>
                </div>
            </div>
        </div>
        <div style="padding:15.6vw 5vw ;background-color: #f4f7fa;">
            <div style="display: flex;margin: 6vw  0;justify-content: space-between;border-radius: 1px solid #d3d6d8;">
                <div style="font-size: 4vw;color:#000;font-weight: 700;">社交媒体</div>
                <div>></div>
            </div>
            <div style="display: flex;margin: 6vw  0;justify-content: space-between;border-radius: 1px solid #d3d6d8;">
                <div style="font-size: 4vw;color:#000;font-weight: 700;">联络方式</div>
                <div>></div>
            </div>
            <div
                style="padding: 0 15vw;font-size: 3.5vw;line-height: 5.3vw;font-weight: 400;color: rgba(0,0, 0, .3);text-align: center;">
                © 2022 Beneunder 深圳减字科技有限公司.版权所有 <br>
                粤ICP备15047322号
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'onIndexs',
    data() {
        return {
            iphone: ''
        }
    },
    created() {
        this.getiphone()
    },
    methods: {
        getiphone() {
            const userAgent = navigator.userAgent.toLowerCase();
            const re =
                /(ipad|iphone os|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile)/i;
            this.iphone = !re.test(userAgent);
            console.log(this.iphone);
        },
    }
}
</script>
<style>
@keyframes bottomTop {
    from {
        opacity: 0;
        transform: translateY(00%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.img-box {
    width: 13.5vw;
    height: 13vw;
    margin-bottom: 1.5vw;
}

.content-left {
    padding: 5vw 0;
}

.name {
    font-size: 1.25vw;
    color: black;
    font-weight: 700;
    line-height: 1.5vw;
    margin-bottom: .5vw;
}

.content-img {
    height: 31vw;
    width: 45vw;
    background-image: url(https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1652247737335.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.content-right {
    position: relative;
    top: -55px;
    left: 0;
    height: 31vw;
    width: 45vw;
}

.content {
    background-color: #f4f7fa;
    /* padding: 0 15vw;
    display: flex;
    justify-content: space-between; */
}

.panel {
    margin: 9vw 0 5vw 0;
    padding: 0 15vw;
}

.title {
    line-height: 100vh;
    text-align: center;
    color: #ffffff;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 5.2vw;
    font-weight: 700;
}

.mask {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .6);
}

.tobu {
    background-image: url(https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1657275278537.jpg);
    position: relative;
    min-height: calc(100vh - 14.6875vw);
    width: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
  