<template>
  <div id="app">
    <div v-if="iphone" class="box" style="position: absolute;width: 100%;z-index: 2;">
      <div :class="router == true ? 'activeRouter' : ''" class="router"
        :style="router == true ? 'position: fixed;color:black;' : ''">
        <div style="width: 8vw;padding:  0 12vw;padding-right: 15vw;">
          <img style="width: 100%;height: 100%;" src="../src/assets/logo.png" alt="">
        </div>
        <div v-for="(item, index) in menuList" @click.stop="clicknav(item.id, null)" :key="item.id"
          @mouseenter="navMouseenter(index)" @mouseleave="navMouseleave()" style="position: relative;">
          <div class="navBoeder">
            <a class="active a-router router-link-active" v-if="item.linkUrl == null" :href="`javascript:;`"
              @click.stop="clicknav(item.id, null)">{{ item.menuTitle }}</a>
            <a v-else class="active a-router" :style="router == true ? 'color:black;' : ''" :href="item.linkUrl">{{
              item.menuTitle
            }}</a>
          </div>
          <div class="active-box animate__animatedanimate__ animate__animated "
            :style="isOver == index ? 'opacity:1;' : 'display:none;'">
            <template v-for="(items, childIndex) in item.childrens">
              <router-link v-if="items.url" :key="childIndex" :to="items.url" class="a-text">{{ items.menuTitle
              }}</router-link>
              <a class="a-text router-link-active" v-else :href="`javascript:;`" :key="childIndex"
                @click.stop="clicknav(item.id, items.id)">{{ items.menuTitle }}</a>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div style="position: absolute;z-index: 2;width: 100%;z-index: 99;" v-else>
      <div :class="router == true ? 'activeRouter' : ''"
        :style="router == true ? 'position: fixed;color:black;border-bottom: 1px solid #d5d5d5;' : '' || hideH5 == true ? 'color:black;background-color: #ffffff;' : ''"
        style="height: 5vh;line-height: 5vh; display: flex;color: #000;justify-content: space-between;width: 100%;z-index: 2;animation: activeRouter 1s;background-color: rgba(242, 242, 242);">
        <div style="margin-left: 10px;color: #000;"> <img style="width: 100%;height: 100%;" src="../src/assets/logo.png"
            alt=""> </div>
        <div @click="hideH5click" style="margin-right: 10px;" class="iconfont icon-liebiao"></div>
      </div>
      <div v-show="hideH5"
        style="position: fixed;width: 100%;z-index: 2;background-color: #ffffff;top: 40px;animation: activeRouter 1s;">
        <div v-for="(item, index) in menuList" :key="item.id" class="guanyu"
          style="padding: 20px;border-bottom: 1px solid #d5d5d5;">
          <div style="display: flex;justify-content: space-between; ">
            <a v-if="item.linkUrl == null" :href="`javascript:;`" @click.stop="clickrouter(item.id, null)"
              class="text-a1">{{
                item.menuTitle }}</a>
            <a v-else :href="item.url" @click.stop="clickrouter(item.id, null)" class="text-a1">{{ item.menuTitle }}</a>
            <div @click="hideshow1(index)" style="margin-right: 10px;font-size: 25px;">></div>
          </div>
          <div v-if="number == index ? true : false"
            style="animation: activeRouter 1s;display: flex;flex-direction: column-reverse;">
            <template v-for="(items, childIndex) in item.childrens">
              <router-link v-if="items.url" :key="childIndex" :to="items.url">{{
                items.menuTitle
              }}</router-link>
              <a v-else @click.stop="clickrouter(item.id, items.id)" :herf="`javascript:;`" :key="childIndex"
                style="border-bottom: 1px solid #d5d5d5;line-height: 2rem;height: 2rem;margin: 5.4vw 0;padding-left: 4.2vw;">
                {{ items.menuTitle }}</a>
            </template>

          </div>
        </div>
      </div>
    </div>
    <div @click="gushiclick(0)" :style="router == true ? 'opacity:1;' : 'opacity: 0;'"
      style="width: 5vw;height: 5vw;border-radius: 50%;position: fixed;z-index: 50;background-color: #5e5f60;bottom: 6.25vw;right: 6.25vw;display: flex;justify-content: center;align-items: center;">
      <div style="color:#ffffff;font-size: 30px;" class="iconfont icon-huidaodingbu"></div>
    </div>
    <router-view />
  </div>
</template>
<script>
import { queryMenu } from "@/apis/home";
import { WOW } from "wowjs";
export default {
  data() {
    return {
      isOver: null,
      router: false,
      iphone: '',
      hide1: false,
      number: null,
      hideH5: false,
      menuList: []
    }
  },
  created() {
    this.getiphone()
    this.getlist()
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true); // 监听（绑定）滚轮滚动事件
    //第一种写法，可以设置wow中属性
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      var wow = new WOW({
        boxClass: "wow", ///动画元件css类（默认为wow）
        animateClass: "animated", //动画css类（默认为animated）
        offset: 10, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      });
      wow.init();
    });
  },
  onshow() {
  },
  methods: {
    clicknav(id, sndId) {
      // this.gushiclick(0)
      this.$store.commit('setcount', id)
      this.$store.commit('setSecondMenuId', sndId)
    },
    async getlist() {
      let list = []
      const urls = [
        { url: '/' },
        { url: '/' },
        { url: '/about' },
        { url: 'http://b2b.0755szl.com' },
        { url: '/brand' },
        { url: '/contact' },
      ]
      const res = await queryMenu()
      console.log(res.data.data);
      res.data.data.forEach((item, index) => {
        item.url = urls[index].url
        list.push(item)
        console.log(item);
      });
      console.log(list);
      this.menuList = list
      localStorage.setItem('menuList', JSON.stringify(list))
    },
    navMouseenter(index) {
      this.isOver = index
    },
    navMouseleave() {
      this.isOver = null
    },
    gushiclick(number, index) {
      document.body.scrollTop = document.documentElement.scrollTop = number
      if (index == 1) {
        this.hideH5 = false
      }
    },
    clickrouter(id, sndId) {
      console.log(id, sndId);
      this.gushiclick(0)
      this.$store.commit('setcount', id)
      this.$store.commit('setSecondMenuId', sndId)
      this.hideH5 = false
    },
    hideH5click() {
      this.hideH5 = !this.hideH5
      this.number = null
    },
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop;
      if (scrollTop >= 20) {
        this.router = true
      } else {
        this.router = false
      }
    },
    getiphone() {
      const userAgent = navigator.userAgent.toLowerCase();
      const re =
        /(ipad|iphone os|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile)/i;
      this.iphone = !re.test(userAgent);
    },
    hideshow1(index) {
      this.hide1 = !this.hide1
      this.number = index
      console.log(index);
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
</script>
<style lang="less">
.navBoeder::after {
  content: "";
  width: 0;
  height: .233vw;
  background-color: black;
  position: absolute;
  bottom: 0;
  left: 50%;
  transition: all .5s;
}

.navBoeder:hover::after {
  left: 0%;
  width: 100%;
}

.navBoeder {
  animation: navborder 0.2s;
}

@keyframes navborder {
  0% {
    transform: translateY(100%)
  }

  100% {
    transform: translate(0);
  }
}

.text-a1 {
  color: black;
  // background-color: #ffffff;
  // width: 100%;
  font-size: 4vw;
  // pointer-events: none;
}

.title-display {
  opacity: 1;
}

.title-display {
  opacity: 0;
}

@keyframes activeRouter {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.activeRouter {
  border-bottom: 1px solid #f1f1f1;
  animation: activeRouter 2s;
  width: 100%;
  transition: all .3s;
  background-color: #ffffff;
  color: black !important;
  z-index: 999;
}

.a-text {
  color: black;
  font-size: .7vw;
  font-weight: 300;
  text-align: center;
  height: 10px;
  line-height: 10px;
  padding: 15px 0;
  margin: 5px;
}

.a-router {
  font-size: .8vw;
  margin: 0 1vw;
  color: black;

}

.router {
  // z-index: 999;
  // background-color: red;
  display: flex;
  position: relative;
  text-align: center;
  // justify-content: center;
  height: 4vw;
  line-height: 4vw;
  // color: black !important;
  background-color: #f2f2f2;
  // align-items: center;
  text-decoration: none;
  // background-image: url('https://official.beneunder.com/img/home_banner.80fec053.jpg');
}


.router>a {
  margin: 0 20px;
  text-decoration: none;
  color: antiquewhite;
}

.active {
  font-weight: bold;
}

.router:hover {
  transition: all .3s;
  background-color: #ffffff;
  color: black;

  .a-router {
    color: black;
  }
}

.active-box:hover {
  color: black;
}

.active-box {
  // visibility: hidden;
  background-color: #ffffff;
  display: flex;
  color: #969696;
  flex: 1;
  flex-direction: column;
  border-radius: 2px;
  padding: 0 1vw;
  opacity: 0;
}

// .a-router:focus~.active-box {
//   opacity: 1;
// }

// .a-router:focus~.active-box {
//   opacity: 1;
//   // visibility: visible;
// }

// .active-box:hover {
//   opacity: 1;
// }
</style>
