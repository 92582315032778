var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.iphone)?_c('div',[_c('div',[_c('video',{staticStyle:{"width":"100%","height":"100vh","object-fit":"cover"},attrs:{"autoplay":"","muted":"","loop":"","playsinline":"","src":"https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651906800691.mp4"},domProps:{"muted":true}}),_c('div',{staticClass:"mask"}),_c('div',{staticClass:"title wow animate__fadeInUp",staticStyle:{"animation":"bottomTop 1s"}},[_vm._v("Anti-Hot 炎热防护科技")])]),_vm._m(0),_vm._m(1),_vm._m(2)]):_c('div',[_c('div',[_c('video',{staticStyle:{"width":"100%","height":"100vh","object-fit":"cover","position":"absolute"},attrs:{"autoplay":"","muted":"","loop":"","playsinline":"","src":"https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651893816388.mp4"},domProps:{"muted":true}}),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel"},[_c('div',{staticStyle:{"padding-right":"50vw","font-size":"2.5vw","font-weight":"700","line-height":"3vw"}},[_vm._v("蕉下极地凉科技")]),_c('div',{staticStyle:{"margin-top":".5vw","font-size":"1vw","font-weight":"200","line-height":"1.5vw"}},[_vm._v("IcingTouch · 高效隔热 · 凉爽降温 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","padding":"0 15vw"}},[_c('div',{staticClass:"content-left"},[_c('div',{staticClass:"name"},[_vm._v(" 强力凉感粒子黑科技 ")]),_c('div',{staticStyle:{"font-size":".8vw","font-weight":"200","line-height":"1.5vw"}},[_vm._v(" 在细腻软性纤维中注入高稳定性凉感粒子，"),_c('br'),_vm._v(" 大大强化纤维散热效率，"),_c('br'),_vm._v(" 提升触肤凉感体验，"),_c('br'),_vm._v(" 在炎热的夏天， "),_c('br'),_vm._v(" 依旧能够明显享受到体感降温的畅快感。 "),_c('br')])]),_c('div',{staticClass:"content-right"},[_c('div',{staticClass:"content-img",staticStyle:{"background-image":"url(https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651739676363.png)"}})])]),_c('div',{staticStyle:{"display":"flex","font-size":".8vw","text-align":"center","flex-direction":"row-reverse","padding":"0 15vw 5vw 0","height":"29vw"}},[_c('div',{staticStyle:{"margin-right":"2vw"}},[_c('div',{staticClass:"img-box"},[_c('img',{staticClass:"img-boxitem",attrs:{"src":"https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651817507097.png","alt":""}})]),_c('span',{staticStyle:{"margin-top":"2vw"}},[_vm._v("元映系列临澈短裤")])]),_c('div',{staticStyle:{"margin-right":"2vw"}},[_c('div',{staticClass:"img-box"},[_c('img',{staticClass:"img-boxitem",attrs:{"src":"https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651817531900.png","alt":""}})]),_c('span',{staticStyle:{"margin-top":"2vw"}},[_vm._v("元映系列阔腿牛仔裤")])]),_c('div',{staticStyle:{"margin-right":"2vw"}},[_c('div',{staticClass:"img-box"},[_c('img',{staticClass:"img-boxitem",attrs:{"src":"https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651817591167.png","alt":""}})]),_c('span',{staticStyle:{"margin-top":"2vw"}},[_vm._v("元映系列牛仔短裤")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dibu"},[_c('div',[_c('div',{staticStyle:{"font-size":".7vw","line-height":".8vw","letter-spacing":"1px","font-weight":"700","margin-bottom":"2.08vw"}},[_vm._v(" 联系我们 ")]),_c('div',{staticClass:"dibu-left",staticStyle:{"font-size":".7vw","line-height":".8vw","letter-spacing":"1px","margin-bottom":"2.08vw"}},[_c('div',{staticStyle:{"color":"#464748"}},[_c('div',{staticStyle:{"margin-bottom":"1vw"}},[_vm._v("商务合作 "),_c('span',{staticStyle:{"margin":"0 10px"}},[_vm._v("cooperation@janezt.com")])]),_c('div',[_vm._v("加入我们 "),_c('span',{staticStyle:{"margin":"0 10px"}},[_vm._v("hr@janezt.com")])])]),_c('div',{staticStyle:{"color":"#464748","padding-left":"7vw"}},[_c('div',{staticStyle:{"margin-bottom":"1vw"}},[_vm._v("联系电话 "),_c('span',[_vm._v("0755-84532595")])]),_c('div',[_vm._v("公司地址 "),_c('span',[_vm._v("深圳市南山区粤海街道深大南大道9968号汉京金融中心14楼")])])])])]),_c('div',{staticClass:"dibu-right"},[_c('div',{staticClass:"dibu-right-text"},[_vm._v("关注我们")]),_c('div',{staticClass:"lxfs"},[_c('div',{staticClass:"iconfont icon-weibo"}),_c('div',{staticClass:"iconfont icon-weixin"}),_c('div',{staticClass:"iconfont icon-douyin"}),_c('div',{staticClass:"iconfont icon-xiaohongshu1"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"rgba(0, 0, 0, .6)","width":"100%","height":"100vh","z-index":"22","position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","width":"100%","text-align":"center","top":"50%","color":"#ffffff"}},[_c('div',{staticStyle:{"font-weight":"700","font-size":"8.5vw"}},[_vm._v("炎热防护科技")]),_c('div',{staticStyle:{"font-weight":"300","font-size":"4.2vw"}},[_vm._v("Anti-Hot ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"8.5vw","padding":"4.2vw"}},[_c('div',{staticStyle:{"font-size":"4.8vw","font-weight":"700","line-height":"5.3vw"}},[_vm._v("蕉下极地凉科技")]),_c('div',{staticStyle:{"font-size":"3.5vw","margin-top":"2.1vw"}},[_vm._v("IcingTouch · 高效隔热 · 凉爽降温")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"6.4vw","padding":"0 4.2vw"}},[_c('div',{staticStyle:{"font-size":"3.7vw","font-weight":"700"}},[_vm._v("强力凉感粒子黑科技")]),_c('div',{staticStyle:{"font-size":"3.2vw","font-weight":"200","margin-top":"2.1vw"}},[_vm._v(" 在细腻软性纤维中注入高稳定性凉感粒子，大大强化纤维散热效率，提升触肤凉感体验，在炎热的夏天，依旧能够明显享受到体感降温的畅快感。 ")]),_c('div',{staticStyle:{"margin-top":"3.5vw"}},[_c('img',{staticStyle:{"width":"100%","height":"63.6vw"},attrs:{"src":"https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651739676363.png","alt":""}})]),_c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651817507097.png","alt":""}}),_c('div',[_vm._v("澄久系列圆领T恤")])]),_c('div',{staticStyle:{"flex":"1"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651817531900.png","alt":""}}),_c('div',[_vm._v("澄久系列休闲卫衣")])]),_c('div',{staticStyle:{"flex":"1"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651817591167.png","alt":""}}),_c('div',[_vm._v("澄久系列休闲束脚裤-")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"8.5vw","padding":"4.2vw"}},[_c('div',{staticStyle:{"font-size":"4.8vw","font-weight":"700","line-height":"5.3vw"}},[_vm._v("高效拒水科技")]),_c('div',{staticStyle:{"font-size":"3.5vw","margin-top":"2.1vw"}},[_vm._v("WaterCombat · 滴水成珠 · 不易渗透")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"6.4vw","padding":"0 4.2vw"}},[_c('div',{staticStyle:{"font-size":"3.7vw","font-weight":"700"}},[_vm._v("仿生抗水黑科技")]),_c('div',{staticStyle:{"font-size":"3.2vw","font-weight":"200","margin-top":"2.1vw"}},[_vm._v(" 表层附载一体仿生水膜，低表层密度能够抗雨水侵袭，具有仿荷叶拒水效果，滴水成珠，无畏大雨环境。 ")]),_c('div',{staticStyle:{"margin-top":"3.5vw"}},[_c('img',{staticStyle:{"width":"100%","height":"63.6vw"},attrs:{"src":"https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/anti/1651739827649.png","alt":""}})]),_c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651740679947.png","alt":""}}),_c('div',[_vm._v("起始系列直柄伞")])]),_c('div',{staticStyle:{"flex":"1"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651740679947.png","alt":""}}),_c('div',[_vm._v("起始系列自动三折伞")])]),_c('div',{staticStyle:{"flex":"1"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"https://portal-resouce.oss-cn-shenzhen.aliyuncs.com/product/1651817789705.png","alt":""}}),_c('div',[_vm._v("蔓延系列三折伞")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"15.6vw 5vw","background-color":"#f4f7fa"}},[_c('div',{staticStyle:{"display":"flex","margin":"6vw  0","justify-content":"space-between","border-radius":"1px solid #d3d6d8"}},[_c('div',{staticStyle:{"font-size":"4vw","color":"#000","font-weight":"700"}},[_vm._v("社交媒体")]),_c('div',[_vm._v(">")])]),_c('div',{staticStyle:{"display":"flex","margin":"6vw  0","justify-content":"space-between","border-radius":"1px solid #d3d6d8"}},[_c('div',{staticStyle:{"font-size":"4vw","color":"#000","font-weight":"700"}},[_vm._v("联络方式")]),_c('div',[_vm._v(">")])]),_c('div',{staticStyle:{"padding":"0 15vw","font-size":"3.5vw","line-height":"5.3vw","font-weight":"400","color":"rgba(0,0, 0, .3)","text-align":"center"}},[_vm._v(" © 2022 Beneunder 深圳减字科技有限公司.版权所有 "),_c('br'),_vm._v(" 粤ICP备15047322号 ")])])
}]

export { render, staticRenderFns }