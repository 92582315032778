<template>
    <div>
        <div v-if="iphone" style="padding:15vw;">
            <div style="font-weight: 700;font-size: 4.6vw;">{{ obj.title }}</div>
            <div style="display:flex;margin: 2vw;">
                <div style="color: #b2b2b2;font-size: 2vw;">{{ obj.createTime }}</div>
            </div>
            <div style=""><img style="width: 100%; height: 100%;" :src="obj.imgUrl" alt=""></div>
            <div style="font-size: 2vw;" v-html="obj.menuContent"></div>
        </div>
        <div v-else style="padding:15vw 5vw;">
            <div style="font-weight: 700;font-size: 5vw;">{{ obj.title }}</div>
            <div style="display:flex;margin: 5vw;">
                <div style="color: #b2b2b2;font-size: 5vw;">{{ obj.createTime }}</div>
            </div>
            <div style=""><img style="width: 100%; height: 100%;" :src="obj.imgUrl" alt=""></div>
            <div style="font-size: 6vw;" v-html="obj.menuContent"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'titleIndex',
    data() {
        return {
            obj: [],
            iphone: ''
        }
    },
    created() {
        this.getvalue()
        this.getiphone()
    },
    methods: {
        getvalue() {
            const res = localStorage.getItem('title')
            this.obj = JSON.parse(res)
            // console.log(JSON.parse(res));
        },
        getiphone() {
            const userAgent = navigator.userAgent.toLowerCase();
            const re =
                /(ipad|iphone os|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile)/i;
            this.iphone = !re.test(userAgent);
        },
    }
}
</script>
<style></style>