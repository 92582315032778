<template>
  <div class="hello">
    <div class="dibu">
      <div>
        <div style="font-size: .7vw;line-height: .8vw;letter-spacing: 1px;font-weight: 700;margin-bottom: 2.08vw;">联系我们
        </div>
        <div class="dibu-left" style="font-size: .7vw;line-height: .8vw;letter-spacing: 1px;margin-bottom: 2.08vw;">
          <div style="color:#464748;">
            <div style="margin-bottom: 1vw;">商务合作 <span style="margin: 0 10px;">cooperation@janezt.com</span></div>
            <div>加入我们 <span style="margin: 0 10px;">hr@janezt.com</span> </div>
          </div>
          <div style="color:#464748;padding-left: 7vw;">
            <div style="margin-bottom: 1vw;">联系电话 <span>0755-84532595</span></div>
            <div>公司地址 <span>广东省深圳市罗湖区宝安南路2014号振业大厦A座21楼</span> </div>
          </div>
        </div>
      </div>
      <div class="dibu-right">
        <div class="dibu-right-text">关注我们</div>
        <div class="lxfs">
          <div class="iconfont icon-weibo"></div>
          <div class="iconfont icon-weixin"></div>
          <div class="iconfont icon-douyin"></div>
          <div class="iconfont icon-xiaohongshu1"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.dibu {
  /* padding-top:3vw; */
  background-color: #ebeef1;
  display: flex;
  justify-content: space-between;
  padding: 1.5vw 15vw;
  border-bottom: 2px solid #d3d6d8;
}

.dibu-left {
  display: flex;
  justify-content: space-between;
}

.dibu-right-text {
  font-size: .7vw;
  line-height: .8vw;
  font-weight: 700;
  margin-bottom: 2.08vw;
  margin-right: 7vw;
}
</style>
