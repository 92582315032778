import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    count:20,
    secondMenuId: null
  },
  getters: {
  },
  mutations: {
    setcount(statre,newval) {
      statre.count = newval
      // console.log(newval,111);
    },
    setSecondMenuId(state,newval) {
      state.secondMenuId = newval
    }
  },
  actions: {
  },
  modules: {
  }
})
